import { getArticleGroup } from '@autovia-uk/polaris-components/sharedPartials/getArticleGroup';
import getSiteBlockGroupContent from './getSiteBlockGroupContent';


/**
* Return Most popular article group.
* @param {String} datePosition Position for date meta/bottom.
* @param {String} title Group title.
* @param {Array} associatedContent Array of objects that have a type and content key.
*/
export const getSiteBlockGroup = ({
  articleCardProps = {
    datePosition: 'meta',
    excerpt: null,
    isHeading: false,
  },
  articlesPerRow = 4,
  associatedContent,
  extraClassNames,
  title,
}) => {
  const groupContentOptions = {
    related: associatedContent,
    key: 'siteBlock',
    titleKey: title,
  };

  const { block, relatedContent } = getSiteBlockGroupContent(groupContentOptions);


  return getArticleGroup({
    articleCardProps,
    articlesPerRow,
    ctaUrl: block && block.url,
    ctaLabel: block && block.url && block.subtitle,
    extraClassNames,
    content: relatedContent,
    title,
  });
};
