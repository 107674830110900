// Regular expression
// eslint-disable-next-line no-useless-escape
const regEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,5}$/;
const regPhoneNumber = /^(((44\s?\d{4}|\(?0\d{4}\)?)\s?\d{3}\s?\d{3})|((44\s?\d{3}|\(?0\d{3}\)?)\s?\d{3}\s?\d{4})|((44\s?\d{2}|\(?0\d{2}\)?)\s?\d{4}\s?\d{4}))$/g;
const digitalInput = /^[0-9]{0,}$/;
const regFullName = /^[a-zA-Z]{2,}(?:[a-zA-Z\s'-]{0,})[a-zA-Z]*$/;
const regPostcode = /^([A-Za-z]{1,2}\d[A-Za-z\d]? ?\d[A-Za-z]{2}|GIR ?0A{2})$/;

export const isEmailValid = (email = '') => regEmail.test(email.toLocaleLowerCase());

export const isPhoneValid = (phoneNumber = '') => !!phoneNumber.match(regPhoneNumber);

export const isDigitInputValueValid = number => digitalInput.test(number);

export const isFullNameValid = (fullName = '') => regFullName.test(fullName);

export const isUKPostcodeValid = (postcode = '') => regPostcode.test(postcode);
