/* eslint-disable react/prop-types */
import React from 'react';
import get from 'lodash.get';
import { OwnerReviewsRatingCard } from 'Molecules/OwnerReviewsRatingCard';
import { getPrimaryMedia } from 'SharedPartialsLocal/getPrimaryMedia';
import { getRelatedContent } from '@autovia-uk/polaris-components/sharedHelpers/getRelatedContent';
import { datalakeProxyUrl } from 'Helpers/config.url';
import { sendDataToDatalakeApi } from 'polaris-coreweb/exports';
import { useDataFetch } from '@autovia-uk/polaris-components/sharedHooks/useDataFetch';
import UkvVehicleDataQuery from '@autovia-uk/polaris-components/sharedQueries/UkvVehicleDataQuery.graphql';

import 'Styles/components/organisms/_OwnerReviewsRatingCard.scss';

export const getOwnerReviewsRatingCard = ({ props }) => {
  const {
    config: {
      globalSettings: {
        name: SiteName,
      },
    },
    layoutData: {
      page: {
        associatedContent,
        cbOwnerReviewPath,
        cbOwnerReviewSummary,
        pageId,
        primaryMedia,
        product,
        rating,
        resources,
        specsPath,
        vehicleSpecList,
      },
    },
  } = props;

  const ratings = [];
  const ctas = [];
  const relatedContent = getRelatedContent({ related: associatedContent, key: 'relatedPages' });

  let overAllRating = rating;
  let tabRating;
  let priceOptions = {
    min: vehicleSpecList?.priceRange?.capPriceMin,
    max: vehicleSpecList?.priceRange?.capPriceMax,
  };
  const rounding = 1;

  if (relatedContent !== null && relatedContent.length > 0 && relatedContent[0].teaserId !== pageId) {
    overAllRating = relatedContent[0].rating || 0;
    const currentSection = relatedContent.filter(item => item.teaserId === pageId);
    if (currentSection && Array.isArray(currentSection) && currentSection.length) {
      tabRating = {
        showTextRating: true,
        value: rating,
        label: `${currentSection[0].title || 'This Section'} Rating`,
        rounding,
      };
    }
  }

  if (overAllRating > 0) {
    ratings.push({
      label: `${SiteName} Rating`,
      value: overAllRating,
      showTextRating: true,
      rounding,
    });
  }

  if (cbOwnerReviewSummary && cbOwnerReviewSummary.avgRating) {
    ratings.push({
      label: 'Owners Rating',
      value: cbOwnerReviewSummary.avgRating,
      showTextRating: true,
      ctaLabel: cbOwnerReviewPath ? 'Read owner reviews' : '',
      ctaUrl: cbOwnerReviewPath,
      rounding,
    });
  } else {
    ratings.push({
      label: 'Owners Rating',
      value: 0,
      extraContent: 'Be the first to review',
    });
  }

  if (tabRating) {
    ratings.push(tabRating);
    priceOptions = null; // Disable price option on subpage, only on Verdict.
  }

  const awardLogo = get(resources, 'award.logo', false);
  const awardName = get(resources, 'award.name', 'Badge');
  let awardMedia = {};
  if (awardLogo) {
    awardMedia = {
      badge: {
        ...awardLogo,
        alt: awardName,
      },
    };
  }

  if (product && specsPath) {
    ctas.push({
      title: 'SPECIFICATIONS',
      url: specsPath,
    });
  }

  return (
    <OwnerReviewsRatingCard
      ctas={ctas}
      extraComponent={getPrimaryMedia(Object.assign(awardMedia, primaryMedia))}
      ratings={ratings}
      priceOptions={priceOptions}
      priceCtaLabel=""
      priceCtaUrl=""
      prosIcon="polaris__icon-plus"
      consIcon="polaris__icon-minus"
      /* eslint-disable-next-line */
      starsPath="M16.716 19.998a.404.404 0 0 1-.217-.041l-5.985-3.147-5.985 3.147a.405.405 0 0 1-.218.041.641.641 0 0 1-.26-.082.48.48 0 0 1-.174-.456l1.128-6.666-4.858-4.72a.453.453 0 0 1-.13-.456c.05-.18.205-.31.39-.331l6.724-.993L10.123.249a.419.419 0 0 1 .39-.249.481.481 0 0 1 .435.25l2.993 6.044 6.68.993a.608.608 0 0 1 .391.331.453.453 0 0 1-.13.456l-4.858 4.72 1.128 6.666a.48.48 0 0 1-.174.456c-.046.082-.131.082-.262.082z"
      starsViewBox="0 0 21 20"
      starsDimensions="20px"
      starsSpacings="5px"
      showProsCons={false}
      extraClassNames={{
        '-article': true,
        'polaris__report-card': true,
      }}
      pageId={pageId}
      ratingsRounding={rounding}
      useVRMDataFetch={useDataFetch({ query: UkvVehicleDataQuery })}
      datalakeProxyUrl={datalakeProxyUrl}
      sendDataToDatalakeApi={sendDataToDatalakeApi}
    />
  );
};
