/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import get from 'lodash.get';
import { compose } from 'recompose';

import { PolarisApp as PolarisContext, withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import { Ad } from '@autovia-uk/polaris-components/components/atoms/Ad';
import { ErrorBoundary } from '@autovia-uk/polaris-components/components/organisms/ErrorBoundary';
import { setPageTargeting } from '@autovia-uk/polaris-components/sharedHelpers/googlePublisherTag';
import {
  Helmet,
  useBlueConic,
  useCrimtan,
  usePermutive,
} from 'polaris-coreweb/exports';

import { withMeta } from 'Protons/withMeta';
import { Header, Footer } from 'Organisms';

import { getCustomizedAdConfig } from '@autovia-uk/polaris-components/sharedHelpers/getCustomizedAdConfig';
import { getMappedContentType } from '@autovia-uk/polaris-components/sharedHelpers/getMappedContentType';
import { getMappedLayoutType } from 'Helpers';
import { getOOPAds } from '@autovia-uk/polaris-components/sharedPartials/getOOPAds';
import { pushWebVitalsToDataLayer } from '@autovia-uk/polaris-components/sharedPartials/pushWebVitalsToDataLayer';

import classNames from 'classnames';
import { useViewportType } from '@autovia-uk/polaris-components/components/protons/ViewportChecker';
import { contextOverride } from './contextOverride';
import { basePropsShape } from './props-shape';


const BaseComponent = (props) => {
  const {
    adConfig: pageConfig = {},
    config,
    config: {
      globalSettings: {
        headerBidding: {
          amazonHeaderBiddingEnabled,
          amazonPubId,
        },
        permutive: {
          projectId,
          publicApiKey,
          namespace,
        },
        blueconicScriptUrl,
        disableBrowserRouter,
        gptTracking,
      },
      fontsUrl,
      branding: {
        logoSquarePng,
      },
      breakpoints,
    },
    type,
    metaData,
    dataLayer,
    children,
    layoutData,
  } = props;

  const layoutType = get(props, 'layoutData.page.layoutType', '');
  const isLandingPage = layoutType === 'landingPage';

  useEffect(() => {
    if (!amazonHeaderBiddingEnabled || !amazonPubId) return;

    window.apstag.init({
      pubID: amazonPubId,
      adServer: 'googletag',
      simplerGPT: true,
    });
  }, []);

  const { isMobile, isDesktop } = useViewportType({
    breakpointsMobile: breakpoints.mobile,
    breakpointsDesktop: breakpoints.desktop,
  });

  const getBelowHeaderAd = ({
    afterNavigationMobileAds,
    afterNavigationDesktopAds,
    contentType,
  }) => {
    if (isDesktop && !afterNavigationDesktopAds) return null;
    if (isMobile && !afterNavigationMobileAds) return null;

    const disableOnTemplate = ['HOMEPAGE', 'CB_OWNER_REVIEW', 'error', 'notFound', 'loading'];
    if (disableOnTemplate.includes(contentType)) return null;

    return (
      <div
        className={classNames({
          'polaris-ad--wrapper-mobile': afterNavigationMobileAds,
          'polaris-ad--wrapper-desktop': afterNavigationDesktopAds,
        })}
      >
        <Ad
          {...getCustomizedAdConfig({
            desktopRule: afterNavigationDesktopAds,
            mobileRule: afterNavigationMobileAds,
          })}
          extraClassNames={{
            '-full-width': true,
            '-below-header': true,
            'hide-mobile': !afterNavigationMobileAds,
            'hide-tablet': !afterNavigationMobileAds,
            'hide-desktop': !afterNavigationDesktopAds,
          }}
          id="refresh-below_header"
          isSkippable
          isSpaceReserved
          targeting={{
            position: 'below_header',
            placement: 'below_header',
            refresh: 'yes',
          }}
        />
      </div>
    );
  };

  const fullWidth = get(props, 'layoutData.page.fullWidth');
  const ads = !isLandingPage ? get(config, 'ads', {}) : {};
  const globalAdSettings = get(config, 'globalSettings.adSettings', { afterNavigationDesktopAds: true, afterNavigationMobileAds: true });
  const { afterNavigationDesktopAds, afterNavigationMobileAds } = globalAdSettings;

  const { targeting, ...adConfig } = {
    ...pageConfig,
    templates: ads,
    globalTargeting: pageConfig.targeting,
  };

  setPageTargeting(targeting);

  const contentType = get(props, 'layoutData.page.contentType', '');
  const mappedContentType = layoutData?.page ? getMappedContentType(props) : 'not-found';
  const contentTypeClass = ` polaris__${mappedContentType.toLowerCase()}--template`;

  const mappedLayoutType = getMappedLayoutType(props);
  const isCommercialPage = mappedLayoutType === 'commercialPage';
  const isCommercialPageTitleImage = mappedLayoutType === 'commercialPageTitleImage';

  if (gptTracking && gptTracking === true) {
    if (typeof window !== 'undefined') {
      import('gpt-tracker')
        .then((tracker) => {
          if (window.ga) {
            tracker.init();
          }
        });
    }
  }

  pushWebVitalsToDataLayer();

  useBlueConic(blueconicScriptUrl);
  useCrimtan();
  usePermutive(
    projectId,
    publicApiKey,
    dataLayer,
    namespace,
    config,
    false, // @todo - once we have `amp` removed from the usePermutive hook, we can remove currently set `false` value
  );

  let uniqueLinkKey = 0;
  const getUniqueLinkKey = () => {
    uniqueLinkKey += 1;
    return uniqueLinkKey;
  };

  return (
    <>
      <Helmet>
        <link
          rel="preload"
          as="style"
          href={fontsUrl ?? '/fonts/fonts.css'}
          key={getUniqueLinkKey()}
        />
        <link
          rel="stylesheet"
          href={fontsUrl ?? '/fonts/fonts.css'}
          media="print"
          onLoad="this.media='all'"
          key={getUniqueLinkKey()}
        />

        <noscript dangerouslySetInnerHTML={{
          __html: `
              <link
                rel="stylesheet"
                href={fontsUrl ?? '/fonts/fonts.css'}
                key={getUniqueKey()}
              />
              `,
        }}
        />
        <link rel="apple-touch-icon" href={logoSquarePng?.src} key={getUniqueLinkKey()} />
      </Helmet>

      <PolarisContext
        config={{
          ...contextOverride(config, adConfig, type),
          metaData,
          disableBrowserRouter,
        }}
      >
        <div className={`polaris__app ${mappedContentType && contentTypeClass}`}>
          {!isLandingPage && (
            <Ad
              type="page-impression"
              id="page-impression"
              isPageImpression
            />
          )}

          <Header stickyEnabled isLandingPage={isLandingPage} />

          {!isLandingPage && !(isCommercialPage || isCommercialPageTitleImage) && getOOPAds()}

          {!fullWidth && !isLandingPage && !(isCommercialPage || isCommercialPageTitleImage) && getBelowHeaderAd({ afterNavigationDesktopAds, afterNavigationMobileAds, contentType })}

          <ErrorBoundary forOrganism>
            {children}
          </ErrorBoundary>

          <Footer isLandingPage={isLandingPage} />
        </div>

      </PolarisContext>

    </>
  );
};

BaseComponent.propTypes = {
  ...basePropsShape,
};

BaseComponent.defaultProps = {
  config: {},
  adConfig: {},
  type: '',
  metaData: {},
  dataLayer: {},
};

const ComposedBaseComponent = compose(withMeta, withPolaris)(BaseComponent);

export default ComposedBaseComponent;
