import PropTypes from 'prop-types';
import {
  breakpointsShape, configPartialShape, dataLayerShape, globalSettingsShape, layoutDataShape,
} from 'Helpers/props-shapes';

const configShape = {
  config: PropTypes.shape({
    ...configPartialShape,
    ...globalSettingsShape,
    ...breakpointsShape,
  }).isRequired,
};

export const specPropsShape = {
  ...configShape,
  ...dataLayerShape,
  ...layoutDataShape,
};

export const vehicleSpecsPropsShape = {
  vehicleSpecs: PropTypes.shape({
    name: PropTypes.string.isRequired,
    summary: PropTypes.shape({
      doors: PropTypes.number,
      driveTrain: PropTypes.string,
      fuelDelivery: PropTypes.string,
      transmission: PropTypes.string,
      fuelType: PropTypes.string,
      CC: PropTypes.number,
      trim: PropTypes.string,
      price: PropTypes.number,
    }).isRequired,
    technicalData: PropTypes.arrayOf(PropTypes.object),
    standardEquipment: PropTypes.arrayOf(PropTypes.object),
  }),
};
