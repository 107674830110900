import React from 'react';
import { OwnerReview } from 'Molecules/OwnerReview';

export const getOwnerReviewBlocks = ({ ownerReviews = [] }) => {
  const ownerReviewBlocks = [];
  if (ownerReviews && Array.isArray(ownerReviews) && ownerReviews.length) {
    for (let i = 0; i < ownerReviews.length; i += 1) {
      const ownerReview = ownerReviews[i];

      const reviewFeatures = [];
      if (ownerReview.version) {
        reviewFeatures.push({
          label: 'Model',
          value: ownerReview.version,
        });
      }
      if (ownerReview.registrationYear) {
        reviewFeatures.push({
          label: 'Year',
          value: ownerReview.registrationYear,
        });
      }
      if (ownerReview.avgAnnualMileage) {
        reviewFeatures.push({
          label: 'Avg annual mileage',
          value: ownerReview.avgAnnualMileage,
        });
      }
      if (ownerReview.ownTime) {
        reviewFeatures.push({
          label: 'Owned',
          value: ownerReview.ownTime,
        });
      }
      if (ownerReview.isUsedCar !== undefined) {
        reviewFeatures.push({
          label: 'Bought Car',
          value: ownerReview.isUsedCar ? 'Used' : 'New',
        });
      }
      if (ownerReview.typicalMpg) {
        reviewFeatures.push({
          label: 'Typical MPG',
          value: ownerReview.typicalMpg,
        });
      }

      const reviewDetails = [];
      if (ownerReview.reviewComment) {
        reviewDetails.push({
          title: 'Tell us what you think about your car?',
          description: ownerReview.reviewComment,
        });
      }
      if (ownerReview.recommend) {
        reviewDetails.push({
          title: 'What\'s the one thing you like most about your car?',
          description: ownerReview.recommend,
        });
      }
      if (ownerReview.improve) {
        reviewDetails.push({
          title: 'If you could improve one thing, what would it be?',
          description: ownerReview.improve,
        });
      }
      if (ownerReview.isRecommended !== null) {
        reviewDetails.push({
          title: 'Would you recommend the car to a friend?',
          isRecommendation: true,
          recommendation: ownerReview.isRecommended,
        });
      }

      const reviewMeta = {
        name: ownerReview.name,
        date: ownerReview.date,
      };

      ownerReviewBlocks.push(
        <OwnerReview
          rating={ownerReview.rating}
          features={reviewFeatures}
          reviewDetails={reviewDetails}
          metadata={reviewMeta}
        />,
      );
    }
  }

  return ownerReviewBlocks;
};
