/**
 * @render react
 * @name VRMConfirmation
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import ReactModal from 'react-modal';
import './_style.scss';
import { Button } from '@autovia-uk/polaris-components/components/atoms/Button';

/**
 * VRMConfirmation component
 *
 * @param {Object} props
 * @param {Object} confOpen - controls whether this modal is open
 * @param {Object} setConfOpen - sets modalOpen
 * @param {Object} setUserConfirmation - setter for state var containing if user has confirmed vehicle
 * @param {Object} vehicleData - contains data about vehicle to confirm correct VRM
 * @param {Object} vrm - vrm being validated and confirmed
 * @param {Object} vrmValid - states whether vrm entered is valid
 * @param {Object} props.context - Extra information coming from Polaris provider
 */

const VRMConfirmation = (
  {
    confOpen,
    setConfOpen,
    setUserConfirmation,
    vehicleData,
    vrm,
    vrmValid,
    context: { cssPrefix },
  },
) => {
  // To prevent errors from server-side rendering
  // For more information view: https://github.com/reactjs/react-modal/issues/580
  if (typeof window !== 'undefined') {
    ReactModal.setAppElement('body');
  }

  const handleConfirm = () => {
    setUserConfirmation(true);
    setConfOpen(false);
  };

  const handleReenter = () => {
    setUserConfirmation(false);
    setConfOpen(false);
  };

  const containerClass = vrmValid ? `${cssPrefix}__user-conf` : `${cssPrefix}__user-conf--invalid`;

  return (
    <div className={confOpen ? containerClass : `${cssPrefix}__user-conf--hidden`}>
      <div className={`${cssPrefix}__user-conf--body`}>
        <p>
          {'You have entered: '}
          <span className={`${cssPrefix}__user-conf--vrm`}>
            {vrm}
          </span>
        </p>
        {vrmValid ? (
          <>
            <p className={`${cssPrefix}__user-conf--model`}>
              {vehicleData.manufacturer}
              {' '}
              {vehicleData.model}
            </p>
            <p className={`${cssPrefix}__user-conf--details`}>
              {vehicleData.year.concat(' - ', vehicleData.colour, ' - ', vehicleData.bodyType, ' - ', vehicleData.fuelType)}
            </p>
            <p className={`${cssPrefix}__user-conf--confirm`}>Is this correct?</p>
            <div className={`${cssPrefix}__user-conf--buttons`}>
              <Button extraClassNames={{ [`${cssPrefix}__user-conf--conf-button -secondary`]: true }} onClick={handleConfirm}>Yes</Button>
              <Button extraClassNames={{ [`${cssPrefix}__user-conf--reenter-button -secondary`]: true }} onClick={handleReenter}>No</Button>
            </div>
          </>
        ) : (
          <>
            <p className={`${cssPrefix}__user-conf--problem`}>
              <img src="/public/info_black.svg" alt="" />
              There was a problem
            </p>
            <p className={`${cssPrefix}__user-conf--details`}>We couldn&apos;t find the details of your vehicle.</p>
            <p className={`${cssPrefix}__user-conf--details`}>Check that the registration you entered is correct!</p>
          </>
        )}
      </div>
    </div>
  );
};

/**
 * Valid props
 */
VRMConfirmation.propTypes = {
  confOpen: PropTypes.bool,
  setConfOpen: PropTypes.func,
  setUserConfirmation: PropTypes.func,
  vehicleData: PropTypes.shape({
    manufacturer: PropTypes.string,
    model: PropTypes.string,
    colour: PropTypes.string,
    fuelType: PropTypes.string,
    bodyType: PropTypes.string,
    engineSize: PropTypes.string,
    transmission: PropTypes.string,
    year: PropTypes.string,
  }),
  vrm: PropTypes.string,
  vrmValid: PropTypes.bool,
  context: PropTypes.shape({
    cssPrefix: PropTypes.string,
  }),
};

/**
 * Default props
 */
VRMConfirmation.defaultProps = {
  confOpen: false,
  setConfOpen: null,
  setUserConfirmation: null,
  vehicleData: {},
  vrm: '',
  vrmValid: false,
  context: {},
};

export default withPolaris(VRMConfirmation);
