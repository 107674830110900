import React from 'react';
import { compose } from 'recompose';
import { getPaginationLimit } from '@autovia-uk/polaris-components/sharedPartials/getPaginationLimit';
import { withPagination } from 'polaris-coreweb/exports';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import { getRelatedContent } from '@autovia-uk/polaris-components/sharedHelpers/getRelatedContent';
import { setPageNumber } from '@autovia-uk/polaris-components/sharedHelpers/setPageNumber';
import { BaseComponent } from 'Templates';
import { withJsonLD, withPreloadPrimaryImage } from 'Protons';
import Helmet from '@autovia-uk/polaris-components/sharedHelpers/getHelmetForArticle';
import { ArticleQueryPagination as query } from './ArticleQuery.graphql';
import { Article } from './Article';

const Composed = compose(
  setPageNumber,
  withPagination({
    query,
    queryVars: getPaginationLimit,
    path: ({ associatedContent }) => getRelatedContent({ related: associatedContent, key: 'indexArticlePageList' }),
  }),
  withJsonLD(Helmet),
  withPolaris,
  withPreloadPrimaryImage,
)(Article);

const ComposedWithBaseComponent = props => (
  <BaseComponent {...props}>
    <Composed {...props} />
  </BaseComponent>
);

export default ComposedWithBaseComponent;
