import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'polaris-coreweb/exports';
import { getAuthorsForJsonLd } from '@autovia-uk/polaris-components/sharedHelpers/getAuthorsForJsonLd';
import { getRating } from './getRating';
import { getAuthors } from '../components/shared-partials/getAuthors';

/**
 * Set Meta tag in the header
 * @returns {*}
 */
const getHelmetForReview = (props) => {
  const {
    layoutData: {
      page: pageData,
      page: {
        altTitle,
        authors: rawAuthors,
        teaserImage,
        date: dateModified,
        created: datePublished,
      },
    },
    metaData: {
      pageTitle: metaTitle,
      pageDescription: metaDescription,
    },
    dataLayer: {
      product,
      productFamily,
      make,
    },
    config: {
      siteUrl,
      branding: {
        logoPng: {
          src: logoImageSrc,
          height: logoImageHeight,
          width: logoImageWidth,
        },
      },
      globalSettings: {
        name: siteName,
      },
    },
  } = props;

  const maxAuthors = getAuthors(rawAuthors);
  const mappedAuthors = list => list.map(author => ({ name: author.author, url: author.authorUrl, image: author.authorImage }));
  const authors = getAuthorsForJsonLd({ authors: mappedAuthors(maxAuthors.authors ? maxAuthors.authors : [maxAuthors]), siteUrl, siteName });
  const image = teaserImage ? [teaserImage.src] : [];

  let jsonld = {
    '@context': 'https://schema.org',
    name: altTitle || metaTitle,
    author: authors,
    image,
    dateModified,
    datePublished,
    publisher: {
      '@type': 'Organization',
      name: siteName,
      logo: {
        '@type': 'ImageObject',
        url: `${siteUrl}${logoImageSrc}`,
        height: logoImageHeight,
        width: logoImageWidth,
      },
    },
  };

  const {
    verdict,
    rating,
  } = pageData;
  jsonld = {
    '@context': 'https://schema.org',
    '@type': 'Car',
    name: product || productFamily || make || metaTitle,
    description: metaDescription,
    review: {
      ...jsonld,
      ...getRating(rating),
      reviewBody: verdict,
    },
  };
  if (make) {
    jsonld.brand = {
      '@type': 'Brand',
      name: make,
    };
  }

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(jsonld)}</script>
      <title>{metaTitle}</title>
      <meta property="og:type" content="article" />
    </Helmet>
  );
};

getHelmetForReview.propTypes = {
  layoutData: PropTypes.shape({
    page: PropTypes.shape({
      altTitle: PropTypes.string,
      authors: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        bio: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        photo: PropTypes.string,
      })),
      teaserImage: PropTypes.shape({
        id: PropTypes.string,
        src: PropTypes.string,
        height: PropTypes.number,
        width: PropTypes.number,
        alt: PropTypes.string,
        source: PropTypes.string,
        credit: PropTypes.string,
        caption: PropTypes.string,
      }),
      date: PropTypes.string,
      created: PropTypes.string,
    }),
  }).isRequired,
  metaData: PropTypes.shape({
    pageTitle: PropTypes.string,
    pageDescription: PropTypes.string,
  }).isRequired,
  dataLayer: PropTypes.shape({
    product: PropTypes.string,
    productFamily: PropTypes.string,
    make: PropTypes.string,
  }).isRequired,
  config: PropTypes.shape({
    siteUrl: PropTypes.string,
    branding: PropTypes.shape({
      logoPng: PropTypes.shape({
        src: PropTypes.string,
        height: PropTypes.number,
        width: PropTypes.number,
      }),
    }),
    globalSettings: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};

export default getHelmetForReview;
