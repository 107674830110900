/**
 * @render react
 * @name OwnerReviewForm
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import 'Styles/components/organisms/_OwnerReviewForm.scss';

import { datalakeProxyUrl } from 'Helpers/config.url';
import { sendDataToDatalakeApi } from 'polaris-coreweb/exports';
import { useDataFetch } from '@autovia-uk/polaris-components/sharedHooks/useDataFetch';
import UkvVehicleDataQuery from '@autovia-uk/polaris-components/sharedQueries/UkvVehicleDataQuery.graphql';
import {
  OwnerReviewHeader,
  OwnerReviewBody,
  OwnerReviewFooter,
} from './OwnerReviewContent';

import CompletedFormContent from './CompletedFormContent';

export const OwnerReviewFormId = 'OWNER_REVIEW_FORM';

/**
 * OwnerReviewForm component
 *
 * @param {Object} props
 * @param {Object} props.context - Extra information coming from Polaris provider
 */

const OWNERREVIEWFORM_MAIN = 'OWNERREVIEWFORM_MAIN';
const OWNERREVIEWFORM_THANKS = 'OWNERREVIEWFORM_THANKS';

const OwnerReviewForm = ({
  context,
}) => {
  const { cssPrefix } = context;
  const [pageType, setPageType] = useState(OWNERREVIEWFORM_MAIN);

  const switchPage = (submitted = false) => {
    if (submitted) setPageType(OWNERREVIEWFORM_THANKS);
  };

  return (
    <div className={`${cssPrefix}__owner-form`}>
      {pageType === OWNERREVIEWFORM_MAIN && (
        <>
          <OwnerReviewHeader context={context} />
          <OwnerReviewBody
            articleId={null}
            useVRMDataFetch={useDataFetch({ query: UkvVehicleDataQuery })}
            datalakeProxyUrl={datalakeProxyUrl}
            sendDataToDatalakeApi={sendDataToDatalakeApi}
            submitAction={switchPage}
            context={context}
          />
          <OwnerReviewFooter context={context} />
        </>
      )}
      {pageType === OWNERREVIEWFORM_THANKS && <CompletedFormContent context={context} />}
    </div>
  );
};

/**
 * Valid props
 */
OwnerReviewForm.propTypes = {
  context: PropTypes.shape({
    cssPrefix: PropTypes.string,
  }),
};

/**
 * Default props
 */
OwnerReviewForm.defaultProps = {
  context: {},
};

export default withPolaris(OwnerReviewForm);
