/**
 * @render react
 * @name Footer
 * @example
 * <Footer />
 * Example when mobile sticky ad should not be rendered on the page:
 * <Footer mobileStickyAd={false} />
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Ad } from '@autovia-uk/polaris-components/components/atoms/Ad';
import { Navigation } from '@autovia-uk/polaris-components/components/molecules/Navigation';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import { Social } from '@autovia-uk/polaris-components/components/molecules/Social';
import { Newsletter } from '@autovia-uk/polaris-components/components/organisms/Newsletter';
import { getSkipLinkComponent } from '@autovia-uk/polaris-components/sharedPartials/getSkipLinkComponent';
import './_style.scss';

/**
 * HELPERS
 * In CMS new lines added via special symbols \r\n
 * swap them for line break tag
 * simple swap '\r\n' -> '<br />' in string doesn't give wanted solution, so making react components is a must
 * https://medium.com/@kevinsimper/react-newline-to-break-nl2br-a1c240ba746
 */
function renderCMSText(str) {
  if (typeof str !== 'string') return '';

  const newLine = '\r\n';
  if (!str.includes(newLine)) return str;

  return (
    <>
      {
        str.split(newLine).map((line, lineIndex) => (
          <React.Fragment key={`line_${lineIndex}`}>
            {line}
            <br />
          </React.Fragment>
        ))
      }
    </>
  );
}

const getPrivacyPreferenceLink = ({ privacyManagerId }) => ({
  title: 'Privacy Preferences',
  url: '#',
  __typename: 'MenuItem',
  onClick: (e) => {
    e.preventDefault();
    if (typeof window._sp_ === 'undefined'
    || typeof window._sp_.loadPrivacyManagerModal === 'undefined') {
      return;
    }
    window._sp_.loadPrivacyManagerModal(privacyManagerId);
  },
});

const Footer = ({
  children,
  context: {
    cssPrefix,
    footer: {
      navigation,
      newsletterConfig,
    },
    globalSettings: {
      copyright,
      sourcepoint,
    },
    social,
    type,
  },
  layoutType,
  mobileStickyAd,
  isLandingPage,
}) => {
  const isCommercialPage = layoutType === 'commercialPage';
  const isCommercialPageTitleImage = layoutType === 'commercialPageTitleImage';

  const footerNavigation = navigation ? [...navigation] : [];
  if (sourcepoint && sourcepoint.privacyManagerId) {
    footerNavigation.push(getPrivacyPreferenceLink(sourcepoint));
  }

  return (
    <>
      {type && type !== 'loading' && getSkipLinkComponent()}
      <footer
        className={`${cssPrefix}__footer`}
        id="footer"
      >
        {children}
        <div className={`${cssPrefix}__footer--inner`}>
          <div className={`${cssPrefix}__footer--row`}>
            <div className={`${cssPrefix}__content ${cssPrefix}__content-padding -footer-nav`}>

              <div className={`${cssPrefix}__footer--navigation`}>
                {navigation && (
                  <Navigation
                    content={footerNavigation}
                  />
                )}
              </div>

              {!isLandingPage && (
                <div className={`${cssPrefix}__footer--extra`}>
                  <Newsletter
                    mainTitle={(newsletterConfig && newsletterConfig.mainTitle) || ''}
                    title={(newsletterConfig && newsletterConfig.titleFooter) || ''}
                    subtitle={(newsletterConfig && newsletterConfig.subtitle) || ''}
                    formUrl={(newsletterConfig && newsletterConfig.newsletterFooterUrl) || ''}
                    cta_label={(newsletterConfig && newsletterConfig.ctaLabel) || ''}
                    placeholder={(newsletterConfig && newsletterConfig.placeholder) || ''}
                    extraClassNames={{ '-footer': true }}
                  />
                </div>
              )}
            </div>
          </div>

          <div className={`${cssPrefix}__footer--row`}>
            <div className={`${cssPrefix}__content ${cssPrefix}__content-padding`}>
              <div className={`${cssPrefix}__footer--copyright`}>
                <p>{renderCMSText(copyright)}</p>
              </div>

              {!isLandingPage && (
                <Social
                  platforms={social.follow.platforms}
                  extraClassNames={{ [`${cssPrefix}__footer--social`]: true }}
                />
              )}
            </div>
          </div>
        </div>

        {!isLandingPage && mobileStickyAd && !(isCommercialPage || isCommercialPageTitleImage) && (
          <Ad
            id="refresh-stickyfooter"
            renderWhenViewable={false}
            type="stickyfooter"
            targeting={{
              position: 'stickyfooter',
              placement: 'stickyfooter',
              refresh: 'yes',
            }}
            isMobileSticky
          />
        )}
      </footer>
    </>
  );
};

/**
 * Valid prop types
 */
Footer.propTypes = {
  children: PropTypes.node,
  context: PropTypes.shape({
    footer: PropTypes.shape({
      logo: PropTypes.shape({
        src: PropTypes.string.isRequired,
        height: PropTypes.number,
        width: PropTypes.number,
        url: PropTypes.string,
        alt: PropTypes.string,
      }).isRequired,
      secondaryLogo: PropTypes.shape({
        src: PropTypes.string.isRequired,
        height: PropTypes.number,
        width: PropTypes.number,
        url: PropTypes.string,
        alt: PropTypes.string,
      }),
      navigation: PropTypes.arrayOf(PropTypes.shape({
        url: PropTypes.string,
        title: PropTypes.string,
      })),
      newsletterConfig: PropTypes.shape({
        mainTitle: PropTypes.string,
        title: PropTypes.string,
        titleFooter: PropTypes.string,
        subtitle: PropTypes.string,
        ctaLabel: PropTypes.string,
        placeholder: PropTypes.string,
        newsletterFooterUrl: PropTypes.string,
      }),
    }),
    social: PropTypes.shape({
      follow: PropTypes.shape(),
    }),
    globalSettings: PropTypes.shape({
      copyright: PropTypes.string,
      sourcepoint: PropTypes.shape({
        privacyManagerId: PropTypes.number,
      }),
    }),
    cssPrefix: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  layoutType: PropTypes.string,
  mobileStickyAd: PropTypes.bool,
  isLandingPage: PropTypes.bool,
};

/**
 * Default props
 */
Footer.defaultProps = {
  children: null,
  layoutType: 'default',
  mobileStickyAd: true,
  isLandingPage: false,
};

export default withPolaris(Footer);
