import React from 'react';
import { compose } from 'recompose';
import { getPaginationLimit } from '@autovia-uk/polaris-components/sharedPartials/getPaginationLimit';
import Helmet from '@autovia-uk/polaris-components/sharedHelpers/getHelmetForAuthor';
import { withPagination } from 'polaris-coreweb/exports';
import { BaseComponent } from 'Templates';
import { withJsonLD } from 'Protons';
import { AuthorQueryPagination as query } from './AuthorQuery.graphql';
import { Author } from './Author';

const Composed = compose(
  withPagination({ query, queryVars: getPaginationLimit }),
  withJsonLD(Helmet),
)(Author);

const ComposedWithBaseComponent = props => (
  <BaseComponent {...props}>
    <Composed {...props} />
  </BaseComponent>
);

export default ComposedWithBaseComponent;
