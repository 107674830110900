import React from 'react';
import Helmet from 'Helpers/getHelmetForOwnerReview';
import { BaseComponent } from 'Templates';
import { OwnerReview } from './OwnerReview';

const ComposedWithBaseComponent = props => (
  <BaseComponent {...props}>
    <Helmet {...props} />
    <OwnerReview {...props} />
  </BaseComponent>
);

export default ComposedWithBaseComponent;
