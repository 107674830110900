/**
 * @render react
 * @name OwnerReview
*/

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import uniqid from 'uniqid';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import { Rating } from '@autovia-uk/polaris-components/components/molecules/Rating';
import { Date } from '@autovia-uk/polaris-components/components/atoms/Date';
import OwnerReviewContentCard from './OwnerReviewContentCard';
import './_style.scss';

/**
 * OwnerReview component
 *
 * @param {float} rating - Overall rating of the review
 * @param {array} features - Array of objects with the following keys: label, value
 * @param {array} reviewDetails - Array of objects with the following keys: title, rating, description
 * @param {boolean} showStarRating - Whether to display the star rating
 * @param {boolean} showTextRating - Whether to display the text for rating
 * @param {boolean} loadStyles - Whether to apply the class names for styling
 * @param {Object} extraClassNames - Extra class names to be applied to the component
 * @param {Object} context - Extra information coming from Polaris provider.
 * @param {Object} metadata - Extra information about the review: user, date
 */
const OwnerReview = ({
  rating,
  features,
  reviewDetails,
  showStarRating,
  showTextRating,
  loadStyles,
  extraClassNames,
  context: { cssPrefix },
  metadata,
}) => (
  <div
    className={classNames({
      [`${cssPrefix}__owner-review`]: loadStyles,
      ...extraClassNames,
    })}
  >
    <div className={`${cssPrefix}__owner-review--header`}>
      <div className={`${cssPrefix}__owner-review--review-info`}>
        {rating >= 0 && (
          <Rating
            highlightTextRating
            rating={rating}
            showStarRating={showStarRating}
            showTextRating={showTextRating}
            showZero
          />
        )}
        {metadata.name && (
          <div className={`${cssPrefix}__owner-review--owner-info`}>
            <p>
              {'by: '}
              <span className={`${cssPrefix}__accent-text`}>{metadata.name}</span>
              {' '}
              {metadata.date && (
                <Date date={metadata.date || null} />
              )}
            </p>
          </div>
        )}
      </div>
      <div className={`${cssPrefix}__owner-review--car-details`}>
        <p className={`${cssPrefix}__owner-review--car-details-heading`}>Car Details</p>
        {features.length > 0 && (
          <div className={`${cssPrefix}__owner-review--details`}>
            {features.map((feature, index) => (
              <React.Fragment key={`feature_${index}`}>
                <div className={`${cssPrefix}__owner-review--details-single`} key={uniqid(`owner-review-${index}`)}>
                  <p className={`${cssPrefix}__owner-review--label`}>
                    {feature.label}
                  </p>

                  <div className={`${cssPrefix}__owner-review--value`}>
                    {feature.value}
                  </div>
                </div>
                <div className={`${cssPrefix}__owner-review--vertical-rule`} />
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    </div>
    <div className={`${cssPrefix}__owner-review--body`}>
      {reviewDetails.length > 0 && (
        <OwnerReviewContentCard
          reviewDetails={reviewDetails}
          showStarRating={showStarRating}
          showTextRating={false}
        />
      )}
    </div>
  </div>
);

/**
 * Valid props
 */
OwnerReview.propTypes = {
  rating: PropTypes.number,
  features: PropTypes.arrayOf(PropTypes.object),
  reviewDetails: PropTypes.arrayOf(PropTypes.object),
  showStarRating: PropTypes.bool,
  showTextRating: PropTypes.bool,
  loadStyles: PropTypes.bool,
  extraClassNames: PropTypes.objectOf(PropTypes.bool),
  context: PropTypes.shape({
    cssPrefix: PropTypes.string,
  }),
  metadata: PropTypes.objectOf(PropTypes.string),
};

/**
 * Default props
 */
OwnerReview.defaultProps = {
  rating: null,
  features: [],
  reviewDetails: [],
  showStarRating: true,
  showTextRating: true,
  loadStyles: true,
  extraClassNames: {},
  context: {},
  metadata: {},
};

export default withPolaris(OwnerReview);
