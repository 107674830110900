/**
 * @render react
 * @name OwnerReviewInput
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import { Input } from '@autovia-uk/polaris-components/components/atoms/Input';
import { Label } from '@autovia-uk/polaris-components/components/molecules/Label';
import { Select } from '@autovia-uk/polaris-components/components/atoms/Select';
import './_style.scss';

/**
 * ReportCard component
 *
 * @param {Object} props
 * @param {Object} label - Title label for input
 * @param {Object} helpLabel - Extra information/example to guide user
 * @param {Object} optional - Indicates whether field is optional
 * @param {Object} specificType - Flags if the input needs to be different (longtext/radio/score/select)
 * @param {Object} type - The type field for the input
 * @param {Object} dropdownContent - The dropdown content, used if type = select
 * @param {Object} index - unique index for each input
 * @param {Object} valueSetter - method used to set value in state
 * @param {Object} inputHandler - generic handler for inputs
 * @param {Object} valueIsValid - variable determining if the input value is valid
 * @param {Object} validityChecker - function to check
 * @param {Object} error - error to display if value not valid
 * @param {Object} props.context - Extra information coming from Polaris provider
 */

const OwnerReviewInput = (
  {
    label,
    helpLabel,
    optional,
    specificType,
    type,
    dropdownContent,
    index,
    valueSetter,
    setValueIsValid,
    validityChecker,
    error,
    context: { cssPrefix },
  },
) => {
  const [displayError, setDisplayError] = useState(false);

  const handleInputChange = (event) => {
    const inputValue = event.target !== undefined ? event.target.value : event.value;

    if (validityChecker) {
      const valid = validityChecker(inputValue);

      setValueIsValid(valid);
      setDisplayError(!valid);
      valueSetter(inputValue);
    } else {
      valueSetter(inputValue);
    }
  };

  // Determine which class/styling should be used for this input
  let inputClass = `${cssPrefix}__owner-form--input`;
  if (specificType === 'longtext') {
    inputClass = inputClass.concat('-long');
  }
  if (specificType === 'radio') {
    inputClass = inputClass.concat('-radio');
  }
  if (specificType === 'score') {
    inputClass = inputClass.concat('-score');
  }

  return (
    <div className={`${cssPrefix}__owner-form--form-input`}>
      <div className={`${cssPrefix}__owner-form--label-container`}>
        <Label
          content={label}
          target={index}
          extraClassNames={{ [`${cssPrefix}__owner-form--label`]: true }}
        />
        {optional && (
          <Label
            content="(optional)"
            target={index}
            extraClassNames={{ [`${cssPrefix}__owner-form--label-help`]: true }}
          />
        )}
      </div>
      {specificType === 'longtext' && (
        <textarea
          id={index}
          className={inputClass}
          onChange={e => handleInputChange(e)}
        />
      )}
      {specificType === 'select' && (
        <Select
          id={index}
          options={dropdownContent}
          onChange={e => handleInputChange(e)}
          reactSelectProps={{
            required: !optional,
          }}
          extraClassNames={{ [inputClass]: true }}
        />
      )}
      { specificType !== 'longtext' && specificType !== 'select' && (
        <div className={`${cssPrefix}__owner-form--input-container`}>
          <Input
            id={index}
            required={!optional}
            type={type}
            value={type === 'radio' ? 'true' : ''}
            name={type}
            onChange={e => handleInputChange(e)}
            extraClassNames={{ [inputClass]: true }}
          />
          { specificType === 'score' && <span> /5</span>}
          { specificType === 'radio' && (
            <>
              <span> Yes</span>
              <Input
                id={index.concat('-2')}
                required={!optional}
                type="radio"
                value="false"
                name={type}
                onChange={e => handleInputChange(e)}
                extraClassNames={{ [inputClass]: true }}
              />
              <span> No</span>
            </>
          )}
        </div>
      )}
      <div className={`${cssPrefix}__owner-form--label-container`}>
        <Label
          content={helpLabel}
          target={index}
          extraClassNames={{ [`${cssPrefix}__owner-form--label-help`]: true }}
        />
        {displayError && (
          <Label
            content={error}
            target={index}
            extraClassNames={{ [`${cssPrefix}__owner-form--label-error`]: true }}
          />
        )}
      </div>
    </div>
  );
};

/**
 * Valid props
 */
OwnerReviewInput.propTypes = {
  label: PropTypes.string,
  helpLabel: PropTypes.string,
  optional: PropTypes.bool,
  specificType: PropTypes.string,
  type: PropTypes.string,
  dropdownContent: PropTypes.arrayOf(PropTypes.shape()),
  index: PropTypes.string,
  valueSetter: PropTypes.func,
  setValueIsValid: PropTypes.func,
  validityChecker: PropTypes.func,
  error: PropTypes.string,
  context: PropTypes.shape({
    cssPrefix: PropTypes.string,
  }),
};

/**
 * Default props
 */
OwnerReviewInput.defaultProps = {
  label: '',
  helpLabel: '',
  optional: false,
  specificType: '',
  type: '',
  dropdownContent: [],
  index: '',
  valueSetter: null,
  setValueIsValid: null,
  validityChecker: null,
  error: '',
  context: {},
};

export default withPolaris(OwnerReviewInput);
