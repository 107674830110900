export const convertFormData = (formData, extraData) => ({
  data: {
    title: 'Owner Review',
    average_annual_mileage: formData.mileage,
    electric_range: formData.electricRange,
    related_article: formData.relatedArticle,
    ownership_years: formData.ownershipYears,
    typical_mpg: formData.mpg,
    recommended: formData.recommended,
    review: formData.review,
    improve: formData.improve,
    recommend: formData.recommend,
    review_rating: formData.rating,
    vrm: formData.vrm,
    name: formData.name,
    email: formData.email,
    postcode: formData.postcode,
  },
  ...extraData,
});

export const date = Date.now();
export const DATALAKE_SERVICE = 'ownerReviews';
