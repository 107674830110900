export const getRating = (ratingValue) => {
  if (!ratingValue || ratingValue < 1) return null;

  return {
    reviewRating: {
      '@type': 'Rating',
      bestRating: 5,
      worstRating: 0,
      ratingValue,
    },
  };
};
