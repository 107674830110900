import PropTypes from 'prop-types';

export const adSettingsShape = {
  adSettings: PropTypes.shape({
    afterNavigationMobileAds: PropTypes.bool,
    afterNavigationDesktopAds: PropTypes.bool,
    numberOfWordsForFirstInjectionDesktop: PropTypes.number,
    numberOfWordsForFirstInjectionMobile: PropTypes.number,
    numberOfWordsBetweenInlineDesktopAds: PropTypes.number,
    numberOfWordsBetweenInlineMobileAds: PropTypes.number,
    minNumOfWordsAfterLastInlineAdDesktop: PropTypes.number,
    minNumOfWordsAfterLastInlineAdMobile: PropTypes.number,
    listGalleryAds: PropTypes.number,
    refreshTime: PropTypes.number,
    refreshBlacklist: PropTypes.string,
    refreshSiteWide: PropTypes.bool,
    minNumOfWordsBeforeTeads: PropTypes.number,
    galleryBlockDesktopAds: PropTypes.number,
    galleryBlockMobileAds: PropTypes.number,
    galleryListDesktopAds: PropTypes.number,
    galleryListMobileAds: PropTypes.number,
    galleryItemDescriptionAds: PropTypes.number,
  }),
};

export const breakpointsShape = {
  breakpoints: PropTypes.shape({
    desktop: PropTypes.arrayOf(
      PropTypes.shape({
        max: PropTypes.number,
        min: PropTypes.number,
      }),
    ),
    mobile: PropTypes.arrayOf(
      PropTypes.shape({
        max: PropTypes.number,
        min: PropTypes.number,
      }),
    ),
  }),
};

export const configPartialShape = {
  header: PropTypes.shape({
    logoImage: PropTypes.string,
  }),
  siteUrl: PropTypes.string.isRequired,
  social: PropTypes.shape({
    share: PropTypes.shape({
      platforms: PropTypes.array,
    }),
  }),
  newsletter: PropTypes.object,
};

export const dataLayerShape = {
  dataLayer: PropTypes.shape({
    product: PropTypes.string,
    productFamily: PropTypes.string,
    make: PropTypes.string,
    productFamilyShortName: PropTypes.string,
    productShortName: PropTypes.string,
    nodeId: PropTypes.string,
  }).isRequired,
};

export const globalSettingsShape = {
  globalSettings: PropTypes.shape({
    newsletterLink: PropTypes.string,
    promoBoxSettings: PropTypes.shape(),
    ...adSettingsShape,
  }).isRequired,
};

export const metaDataShape = {
  metaData: PropTypes.shape({
    pageTitle: PropTypes.string,
    pageDescription: PropTypes.string,
    ogTitle: PropTypes.string,
    ogDescription: PropTypes.string,
    twitterHandle: PropTypes.string,
    twitterTitle: PropTypes.string,
    twitterDescription: PropTypes.string,
  }),
};

export const layoutDataShape = {
  layoutData: PropTypes.shape({
    page: PropTypes.shape({
      associatedContent: PropTypes.arrayOf(PropTypes.object),
      body: PropTypes.arrayOf(PropTypes.object),
      breadcrumbs: PropTypes.arrayOf(PropTypes.object),
      isSponsored: PropTypes.bool,
      kicker: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          label: PropTypes.string,
          url: PropTypes.string,
        }),
      ]),
      primaryMedia: PropTypes.object,
      sponsor: PropTypes.object,
      subtitle: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.object),
      title: PropTypes.string,
      url: PropTypes.string,
      layoutType: PropTypes.string,
      hideTitle: PropTypes.bool,
      fullWidth: PropTypes.bool,
      authors: PropTypes.arrayOf(PropTypes.object),
      monetising: PropTypes.arrayOf(
        PropTypes.shape({
          config: PropTypes.arrayOf(
            PropTypes.shape({
              key: PropTypes.string.isRequired,
              value: PropTypes.string.isRequired,
            }),
          ).isRequired,
          enabled: PropTypes.bool.isRequired,
          service: PropTypes.string.isRequired,
        }).isRequired,
      ),
      heroImage: PropTypes.shape({
        heroImageDesktop: PropTypes.object,
        heroImageMobile: PropTypes.object,
      }),
      ...metaDataShape,
    }).isRequired,
  }).isRequired,
};

export const paginationShape = {
  pagination: PropTypes.shape({
    isLoading: PropTypes.bool,
    loadMore: PropTypes.func,
    page: PropTypes.number,
    pages: PropTypes.object,
    setPageRef: PropTypes.func,
    hasMore: PropTypes.bool,
    nextUrl: PropTypes.string,
  }).isRequired,
};
