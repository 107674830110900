import React from 'react';
import PropTypes from 'prop-types';

import { getRelatedContent } from '@autovia-uk/polaris-components/sharedHelpers/getRelatedContent';
import { getBody } from '@autovia-uk/polaris-components/sharedPartials/getBody';
import { getSponsorInfo } from '@autovia-uk/polaris-components/sharedPartials/getSponsorInfo';
import { getMultipageNavigation } from '@autovia-uk/polaris-components/sharedPartials/getMultipageNavigation';
import { getArticleBottomComponents } from '@autovia-uk/polaris-components/sharedPartials/getArticleBottomComponents';
import { useViewportType } from '@autovia-uk/polaris-components/components/protons/ViewportChecker';
import { getPrimaryMedia } from './getPrimaryMedia';

export const getArticleBody = ({
  bodyInjected = [],
  props,
  template = 'article',
}) => {
  const {
    layoutData: {
      page: {
        associatedContent,
        fullWidth,
        layoutType,
        isSponsored,
        primaryMedia,
        sponsor,
        url,
      },
    },
    config: {
      breakpoints,
    },
  } = props;

  const isLandingPage = layoutType === 'landingPage';

  const { isMobile } = useViewportType({
    breakpointsMobile: breakpoints.mobile,
    breakpointsDesktop: breakpoints.desktop,
  });

  const multipage = getRelatedContent({ related: associatedContent, key: 'relatedPages' });
  const topComponents = (
    <div className="polaris__simple-grid--main">
      {getMultipageNavigation({ content: multipage, currentUrl: url })}
      {getPrimaryMedia(primaryMedia)}
      {getSponsorInfo({ isSponsored, sponsor })}
    </div>
  );

  return (
    <div className={`polaris__content ${isLandingPage || fullWidth ? '-body-full-width' : ''} -body-copy`}>
      {getBody({
        bodyInjected,
        template,
        disableAdInjections: isLandingPage,
        bottomComponents: !isLandingPage && getArticleBottomComponents({ props }),
        topComponents,
        isMobile,
      })}
    </div>
  );
};

getArticleBody.propTypes = {
  bodyInjected: PropTypes.arrayOf(PropTypes.object).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  props: PropTypes.object.isRequired,
  layoutData: PropTypes.shape({
    page: PropTypes.shape({
      associatedContent: PropTypes.arrayOf(PropTypes.object),
      fullWidth: PropTypes.string,
      layoutType: PropTypes.string,
      isSponsored: PropTypes.bool,
      primaryMedia: PropTypes.object,
      sponsor: PropTypes.object,
      url: PropTypes.string,
    }).isRequired,
  }).isRequired,
  template: PropTypes.string,
};

getArticleBody.defaultProps = {
  template: 'article',
};
