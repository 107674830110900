/**
 * @render react
 * @name OwnerReviewContent
 */

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form } from '@autovia-uk/polaris-components/components/molecules/Form';
import { Button } from '@autovia-uk/polaris-components/components/atoms/Button';

import {
  isEmailValid,
  isUKPostcodeValid,
  isDigitInputValueValid,
} from 'Molecules/OwnerReviewFormModal/helpers/validation';
import { convertFormData, DATALAKE_SERVICE, date } from 'Molecules/OwnerReviewFormModal/helpers/index';

import { VRMInput } from 'Molecules/VRMInput';
import './_style.scss';
import OwnerReviewInput from './OwnerReviewInput';


/**
* OwnerReviewForm component
*
* @param {Object} props
* @param {Object} props.articleId - Id of review article from which the form modal was triggered
* @param {Object} props.useVRMDataFetch - hook used to fetch data about the vrm inputted
* @param {Object} props.datalakeProxyUrl - url to send data to
* @param {Object} props.sendDataToDatalakeApi - method used to send data to datalake
* @param {Object} props.context - Extra information coming from Polaris provider
*/

export const OwnerReviewHeader = (
  {
    context: { cssPrefix },
  },
) => (
  <>
    <div className={`${cssPrefix}__owner-form--modal-header`}>
      <img src="/public/logo-carbuyer.png" alt="Carbuyer Logo" className={`${cssPrefix}__owner-form--header-logo`} />
    </div>
  </>
);

/**
* Valid props
*/
OwnerReviewHeader.propTypes = {
  context: PropTypes.shape({
    cssPrefix: PropTypes.string,
  }),
};

/**
* Default props
*/
OwnerReviewHeader.defaultProps = {
  context: {},
};

export const OwnerReviewBody = (
  {
    articleId,
    useVRMDataFetch,
    datalakeProxyUrl,
    sendDataToDatalakeApi,
    submitAction,
    context: { cssPrefix },
  },
) => {
  const [formValid, setFormValid] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [emailIsValid, setEmailIsValid] = useState(false);
  const [postcode, setPostcode] = useState('');
  const [postcodeIsValid, setPostcodeIsValid] = useState(false);
  const [vrm, setVrm] = useState('');
  const [vrmIsValid, setVrmIsValid] = useState(false);
  const [ownershipYears, setOwnershipYears] = useState('');
  const [mileage, setMileage] = useState('');
  const [mpg, setMpg] = useState('');
  const [electricRange, setElectricRange] = useState('');
  const [review, setReview] = useState('');
  const [reviewIsValid, setReviewIsValid] = useState(false);
  const [rating, setRating] = useState('');
  const [ratingIsValid, setRatingIsValid] = useState(false);
  const [recommend, setRecommend] = useState('');
  const [improve, setImprove] = useState('');
  const [recommended, setRecommended] = useState('');

  const isRatingValid = value => isDigitInputValueValid(value) && value <= 5 && value >= 1;
  const isReviewValid = value => value !== '';

  // Detect any change to the validity of the form
  useEffect(() => {
    setFormValid(emailIsValid && postcodeIsValid && vrmIsValid && ratingIsValid && reviewIsValid);
  }, [emailIsValid, postcodeIsValid, vrmIsValid, ratingIsValid, reviewIsValid]);

  const userDetailFields = [
    {
      label: 'First name',
      help: '(e.g. John)',
      valueSetter: setFirstName,
    },
    {
      label: 'Last name',
      help: '(e.g. Smith)',
      valueSetter: setLastName,
    },
    {
      label: 'Email',
      help: '(e.g. john.smith@gmail.com)',
      type: 'email',
      valueSetter: setEmail,
      setValueIsValid: setEmailIsValid,
      validityChecker: isEmailValid,
      errorMsg: 'Please enter a valid email',
    },
    {
      label: 'Postcode',
      help: '(e.g. SW1 2AA)',
      valueSetter: setPostcode,
      setValueIsValid: setPostcodeIsValid,
      validityChecker: isUKPostcodeValid,
      errorMsg: 'Please enter a valid postcode',
    },
  ];

  const carDetailFields = [
    {
      label: 'Registration number (VRN)',
      help: '(e.g. GU52 ELA)',
      specificType: 'vrm',
      valueSetter: setVrm,
      setValueIsValid: setVrmIsValid,
    },
    {
      label: 'How long have you owned the car?',
      specificType: 'select',
      dropdownContent: [
        {
          label: 'Less than a year',
          value: 'Less than a year',
        },
        {
          label: '1 - 2 years',
          value: '1 - 2 years',
        },
        {
          label: '2 - 3 years',
          value: '2 - 3 years',
        },
        {
          label: '3 - 5 years',
          value: '3 - 5 years',
        },
        {
          label: 'More than 5 years',
          value: 'More than 5 years',
        },
      ],
      valueSetter: setOwnershipYears,
    },
    {
      label: 'Average annual mileage',
      specificType: 'select',
      dropdownContent: [
        {
          label: '0 - 4,999',
          value: '0 - 4,999',
        },
        {
          label: '5,000 - 9,999',
          value: '5,000 - 9,999',
        },
        {
          label: '10,000 - 14,999',
          value: '10,000 - 14,999',
        },
        {
          label: '15,000 - 19,999',
          value: '15,000 - 19,999',
        },
        {
          label: 'More than 20,000',
          value: 'More than 20,000',
        },
      ],
      valueSetter: setMileage,
    },
    {
      label: 'Average MPG',
      help: '(e.g. 22mpg)',
      optional: true,
      valueSetter: setMpg,
      type: 'number',
    },
    {
      label: 'Average electric range',
      help: '(e.g. 194 miles)',
      optional: true,
      valueSetter: setElectricRange,
      type: 'number',
    },
  ];

  const reviewDetailFields = [
    {
      label: 'Tell us what you think about your car?',
      specificType: 'longtext',
      valueSetter: setReview,
      setValueIsValid: setReviewIsValid,
      validityChecker: isReviewValid,
      errorMsg: 'Please enter a review',
    },
    {
      label: 'Overall Score:',
      help: '(e.g. 4)',
      specificType: 'score',
      type: 'number',
      valueSetter: setRating,
      setValueIsValid: setRatingIsValid,
      validityChecker: isRatingValid,
      errorMsg: 'Please enter a rating between 1 and 5',
    },
    {
      label: 'What\'s the one thing you like most about your car?',
      specificType: 'longtext',
      valueSetter: setRecommend,
    },
    {
      label: 'If you could improve one thing, what would it be?',
      specificType: 'longtext',
      valueSetter: setImprove,
    },
    {
      label: 'Would you recommend the car to a friend?',
      specificType: 'radio',
      type: 'radio',
      valueSetter: setRecommended,
    },
  ];

  const extraData = {
    service: DATALAKE_SERVICE,
    timestamp: date,
  };

  const myRef = useRef(null);
  const executeScroll = () => myRef.current.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
    inline: 'center',
  });

  const handleFormSubmit = () => {
    let isFormValid = true;

    setEmailIsValid(isEmailValid(email));
    if (!emailIsValid) isFormValid = false;

    setPostcodeIsValid(isUKPostcodeValid(postcode));
    if (!postcodeIsValid) isFormValid = false;

    if (!vrmIsValid) isFormValid = false;

    setRatingIsValid(isRatingValid(rating));
    if (!ratingIsValid) isFormValid = false;

    setReviewIsValid(isReviewValid(review));

    if (isFormValid) {
      const formData = {
        mileage,
        vrm,
        ownershipYears,
        name: firstName.concat(' ', lastName),
        mpg,
        improve,
        recommend,
        recommended: recommended === 'true' ? '1' : '0',
        review,
        rating,
        email,
        postcode,
        electricRange,
        relatedArticle: articleId,
      };

      sendDataToDatalakeApi(convertFormData(formData, extraData), datalakeProxyUrl);

      submitAction(true);
    } else {
      executeScroll();
    }
  };

  return (
    <div className={`${cssPrefix}__owner-form--modal-body`}>
      <img src="/public/logo-driver-power.png" alt="Driver Power Logo" className={`${cssPrefix}__owner-form--dp-logo`} />
      <h5 className={`${cssPrefix}__owner-form--title`}>Write your review</h5>
      <p className={`${cssPrefix}__owner-form-description`}>Tell us what you like and dislike about your car. Help others to make the right car buying choices.</p>
      <div className={`${cssPrefix}__owner-form--form-body`}>
        <Form
          // action=""
          onSubmit={handleFormSubmit}
        >
          <div className={`${cssPrefix}__owner-form--user-details`}>
            {userDetailFields && userDetailFields.map((field, index) => (
              <OwnerReviewInput
                label={field.label}
                helpLabel={field.help}
                optional={field.optional}
                index={'user-'.concat(index)}
                inputHandler={field.inputHandler}
                valueSetter={field.valueSetter}
                setValueIsValid={field.setValueIsValid}
                validityChecker={field.validityChecker}
                error={field.errorMsg}
                type={field.type}
                specificType={field.specificType}
                key={'userDetailsInput-'.concat(index)}
              />
            ))}
          </div>
          <h5 className={`${cssPrefix}__owner-form--title`}>About your car</h5>
          <div className={`${cssPrefix}__owner-form--car-details`}>
            {carDetailFields && carDetailFields.map((field, index) => {
              if (field.specificType === 'vrm') {
                return (
                  <VRMInput
                    label={field.label}
                    helpLabel={field.help}
                    index={'car-'.concat(index)}
                    inputHandler={field.inputHandler}
                    valueSetter={field.valueSetter}
                    valueValidSetter={field.valueValidSetter}
                    setValueIsValid={field.setValueIsValid}
                    validityChecker={field.validityChecker}
                    error={field.errorMsg}
                    type={field.type}
                    specificType={field.specificType}
                    useVRMDataFetch={useVRMDataFetch}
                    key={`vrm-${index}`}
                  />
                );
              }
              return (
                <OwnerReviewInput
                  label={field.label}
                  helpLabel={field.help}
                  optional={field.optional}
                  dropdownContent={field.dropdownContent}
                  index={'car-'.concat(index)}
                  inputHandler={field.inputHandler}
                  valueSetter={field.valueSetter}
                  setValueIsValid={field.setValueIsValid}
                  validityChecker={field.validityChecker}
                  error={field.errorMsg}
                  type={field.type}
                  specificType={field.specificType}
                  key={'carDetailsInput-'.concat(index)}
                />
              );
            })}
          </div>
          <h5 className={`${cssPrefix}__owner-form--title`}>Your review</h5>
          <div className={`${cssPrefix}__owner-form--review-details`}>
            {reviewDetailFields && reviewDetailFields.map((field, index) => (
              <OwnerReviewInput
                label={field.label}
                helpLabel={field.help}
                optional={field.optional}
                index={'review-'.concat(index)}
                inputHandler={field.inputHandler}
                valueSetter={field.valueSetter}
                setValueIsValid={field.setValueIsValid}
                validityChecker={field.validityChecker}
                error={field.errorMsg}
                type={field.type}
                specificType={field.specificType}
                key={'reviewInput-'.concat(index)}
              />
            ))}
          </div>
          <div className={`${cssPrefix}__owner-form--submit-wrapper`}>
            <Button
              type="submit"
              label="Submit your review"
              extraClassNames={{ [`${cssPrefix}__owner-form--submit`]: true }}
              disabled={!formValid}
              srLabel="Leave your review"
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

/**
* Valid props
*/
OwnerReviewBody.propTypes = {
  articleId: PropTypes.string,
  useVRMDataFetch: PropTypes.object,
  datalakeProxyUrl: PropTypes.string,
  sendDataToDatalakeApi: PropTypes.func,
  submitAction: PropTypes.func,
  context: PropTypes.shape({
    cssPrefix: PropTypes.string,
  }),
};

/**
* Default props
*/
OwnerReviewBody.defaultProps = {
  articleId: '',
  useVRMDataFetch: {},
  datalakeProxyUrl: '',
  sendDataToDatalakeApi: null,
  submitAction: null,
  context: {},
};

export const OwnerReviewFooter = (
  {
    context: { cssPrefix },
  },
) => (
  <div className={`${cssPrefix}__owner-form--modal-footer`}>
    <p>Privacy Policy</p>
    <p className={`${cssPrefix}__owner-form--disclaimer`}>
      Submit your review and VRN to join our Driver Power community so we can provide you with access to news and special offers from Autovia and from specially selected
      third party partners with whom we may share your details. By submitting your details you consent to us using your information this way and as described in more detail in our
      <a href="https://www.autovia.co.uk/privacy-policy/" target="_blank" rel="noopener noreferrer"> privacy policy</a>
      .
    </p>
  </div>
);

/**
* Valid props
*/
OwnerReviewFooter.propTypes = {
  context: PropTypes.shape({
    cssPrefix: PropTypes.string,
  }),
};

/**
* Default props
*/
OwnerReviewFooter.defaultProps = {
  context: {},
};
