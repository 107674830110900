/**
 * @render react
 * @name CompletedFormContent
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@autovia-uk/polaris-components/components/atoms/Button';
import './_style.scss';

/**
 * CompletedFormModal component
 *
 * @param {Object} props
 * @param {Object} props.context - Extra information coming from Polaris provider
 */

const CompletedFormContent = (
  {
    context: { cssPrefix },
  },
) => (
  <>
    <div className={`${cssPrefix}__completed-form--header`}>
      <div className={`${cssPrefix}__completed-form--cb-logo`}>
        <img src="/public/logo-carbuyer.png" alt="CarBuyer Logo" />
      </div>
      <img src="/public/logo-driver-power.png" alt="Driver Power logo" />
    </div>
    <div className={`${cssPrefix}__completed-form--body`}>
      <h3>Thank you for your review</h3>
      <p>Now tell us a little more about your car, dealer and other services in the Driver Power survey. Help shape the future of cars and the car industry.</p>
      <div className={`${cssPrefix}__completed-form--dp-survey`}>
        <img src="/public/Driver-Power-2021.png" alt="Driver Power title" className={`${cssPrefix}__complete-form--dp-img`} />
        <h6>Take the Driver Power survey and you could win £100</h6>
        <p>Tell us about your car in the Driver Power survey, you’ll be entered into a prize draw where we’re giving away three £100 Amazon vouchers every month.</p>
        <Button extraClassNames={{ [`${cssPrefix}__dp-survey--cta`]: true }} onClick={() => window.open('https://survey.simpcar.co.uk/driverpower/index.php?sid=149', '_blank')}>Take the survey</Button>
      </div>
    </div>
  </>
);

/**
 * Valid props
 */
CompletedFormContent.propTypes = {
  context: PropTypes.shape({
    cssPrefix: PropTypes.string,
  }),
};

/**
 * Default props
 */
CompletedFormContent.defaultProps = {
  context: {},
};

export default CompletedFormContent;
