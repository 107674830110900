import React from 'react';
import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading';
import { getBreadcrumbs } from '@autovia-uk/polaris-components/sharedPartials/getBreadcrumbs';
import { getIndexPageItems } from '@autovia-uk/polaris-components/sharedPartials/getIndexPageItems';
import { getLoadMore } from '@autovia-uk/polaris-components/sharedPartials/getLoadMore';
import { getTaxonomyFilter } from '@autovia-uk/polaris-components/sharedPartials/getTaxonomyFilter';
import { getSponsorInfo } from '@autovia-uk/polaris-components/sharedPartials/getSponsorInfo';
import { useViewportType } from '@autovia-uk/polaris-components/components/protons/ViewportChecker';

import { indexPropsShape } from './props-shape';
import 'Styles/components/templates/_Index.scss';

const IndexTemplate = ({
  layoutData: {
    page: {
      url,
      title,
      breadcrumbs,
      filters,
      showFilters,
      sponsor,
      hideTitle,
    },
  },
  pagination: {
    hasMore,
    isLoading,
    loadMore,
    nextUrl,
    pages,
    setPageRef,
  },
  config: {
    adBreakPoints,
    breakpoints,
    globalSettings: {
      promoBoxSettings,
    },
    newsletterConfig,
  },
}) => {
  const config = {
    newsletterConfig: {
      ...newsletterConfig,
      extraClassNames: {
        '-full-width': true,
        '-index': true,
      },
    },
    promoBoxConfig: {
      ...promoBoxSettings,
      extraClassNames: {
        '-index': true,
      },
    },
    adBreakPoints,
  };
  const extraClassNames = { '-news-index': true, '-index': true, 'polaris__core-content': true };

  const { isMobile } = useViewportType({
    breakpointsMobile: breakpoints.mobile,
    breakpointsDesktop: breakpoints.desktop,
  });

  const loadMoreParams = {
    label: isLoading ? 'Loading...' : 'Load More',
    loadMore,
    isLoading,
    extraClassNames: {
      'polaris__load-more--small': true,
    },
    href: nextUrl,
  };

  const taxonomyFilters = Array.isArray(filters) ? filters.map(f => ({
    label: f.title,
    value: f.url,
  })) : null;
  if (taxonomyFilters && taxonomyFilters.length) {
    taxonomyFilters.unshift({
      label: 'ALL',
      value: url,
    });
  }
  const taxonomyFilterParams = {
    label: 'Show me:',
    listModeOptions: {
      seeMoreLabel: 'See more',
      seeLessLabel: 'See less',
      icon: 'triangle-down',
    },
    currentPageUrl: url,
    content: taxonomyFilters,
  };

  const taxonomyFiltersMarkup = (
    <div className="polaris__gray--bg">
      {getTaxonomyFilter(taxonomyFilterParams)}
    </div>
  );

  return (
    <>
      <div className="polaris__body">
        <main className="polaris__main" id="main">
          <div className="polaris__core-content polaris__content">
            <div className="polaris__index--headings-container">
              {getBreadcrumbs({ breadcrumbs })}
              {!hideTitle && (
                <Heading size={1} extraClassNames={{ '-content-title': true }}>
                  {title}
                </Heading>
              )}
              {getSponsorInfo({ sponsor })}
            </div>
          </div>

          <div className="polaris__core-content polaris__content">
            {showFilters && taxonomyFilters !== null && taxonomyFilters.length > 0 && taxonomyFiltersMarkup}
          </div>

          <div className="polaris__content">
            <div data-previous="1" ref={setPageRef} />
            {getIndexPageItems({
              config,
              extraClassNames,
              newsletterPosition: 9,
              pages,
              promoboxPosition: false,
              setPageRef,
              templateType: 'indexTemplate',
              articleCardProps: { datePosition: 'meta', showRating: true },
              isMobile,
            })}
          </div>

          <div className="polaris__core-content polaris__content">
            {hasMore && getLoadMore(loadMoreParams)}
          </div>
        </main>
      </div>
    </>
  );
};

IndexTemplate.propTypes = {
  ...indexPropsShape,
};

export { IndexTemplate };
