/**
 * @render react
 * @name OwnerSummary
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from '@autovia-uk/polaris-components/components/molecules/Link';
import { Rating } from '@autovia-uk/polaris-components/components/molecules/Rating';
import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading';
import { Image } from '@autovia-uk/polaris-components/components/atoms/Image';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import './_style.scss';

/**
* OwnerSummary component
* @param {string} recommendationPercent - percent text to show
* @param {string} ratingsTitle - Title of Ratings section
* @param {string} reviewLabel - label for the (5 reviews) text
* @param {node} commonProblems - HTML element for the common problems section
* @param {boolean} loadStyles - Whether to apply the class names for styling
* @param {object} extraClassNames - extra classes to be applied to the component
* @param {object} context - extra information coming from Polaris provider
*/

const OwnerSummary = ({
  recommendationPercent,
  ratingsTitle,
  commonTitle,
  commonProblems,
  loadStyles,
  extraClassNames,
  context: { cssPrefix },
}) => {
  const getRating = rating => (
    <>
      {rating.ratingValue > 0 && (
        <div className={`${cssPrefix}__owner-summary--rating`}>
          <Rating
            rating={parseFloat(rating.ratingValue)}
            numberOfStars={rating.ratingTotal}
            widgetDimensions="23px"
            widgetSpacings="2px"
          />
        </div>
      )}

      {rating.reviewCount && (
        <div className={`${cssPrefix}__owner-summary--review`}>
          {rating.reviewCount && (
            <span>
              {rating.ratingValue}
              <span className={`${cssPrefix}__owner-summary--review-count-text`}>
                {' out of '}
              </span>
              {rating.ratingTotal}
            </span>
          )}
        </div>
      )}
    </>
  );

  // While there aren't enough ratings to show number of ratings, just show
  // the range of stars. This can be removed and reverted to using ratingsBreakdown
  // when we have enough ratings on both carbuyer and autoexpress
  const defaultRatings = [];
  for (let i = 0; i < 5; i += 1) {
    defaultRatings.push({
      ratingValue: i + 1,
      ratingTotal: 5,
      reviewCount: 1,
    });
  }

  // Determine whether this car is recommended by more than 50%
  const isNotRecommendation = Number(recommendationPercent && recommendationPercent.replace('%', '')) < 50;
  const recommendation = isNotRecommendation ? {
    iconsrc: '/public/thumbs_down_red.svg',
    iconalt: 'thumbs down',
    class: '-notrecommended',
  } : {
    iconsrc: '/public/thumbs_up_green.svg',
    iconalt: 'thumbs up',
    class: '',
  };

  return (
    <div
      className={classNames({
        [`${cssPrefix}__owner-summary`]: loadStyles,
        ...extraClassNames,
      })}
    >
      <div className={`${cssPrefix}__owner-summary--reviews-title`}>
        <Heading>Reviews</Heading>
        <Image src="/public/Driver_Power_2021_logo.png" />
      </div>
      <div className={`${cssPrefix}__owner-summary--reviews-container`}>
        {defaultRatings.length > 0 && (
          <div className={`${cssPrefix}__owner-summary--ratings-container`}>
            {ratingsTitle && (
              <Heading size={4} extraClassNames={{ [`${cssPrefix}__owner-summary--section-title`]: true }}>{ratingsTitle}</Heading>
            )}
            <ul className={`${cssPrefix}__owner-summary--reviews-list`}>
              {defaultRatings.sort((a, b) => b.ratingValue - a.ratingValue).map((rating, index) => (
                rating.ratingValue > 0 && (
                  <li key={`rating-${index}`}>
                    { rating.reviewUrl ? (
                      <Link href={rating.reviewUrl}>
                        { getRating(rating) }
                      </Link>
                    ) : (
                      getRating(rating)
                    )}
                  </li>
                )
              ))}
            </ul>
          </div>
        )}
        <div className={`${cssPrefix}__owner-summary--details-summary`}>
          {(recommendationPercent) && (
            <div className={`${cssPrefix}__owner-summary--recommendation`.concat(recommendation.class)}>
              <img src={recommendation.iconsrc} alt={recommendation.iconalt} />
              <span className={`${cssPrefix}__owner-summary--recommendation-percent`.concat(recommendation.class)}>{recommendationPercent}</span>
              <span className={`${cssPrefix}__owner-summary--recommendation-text`}>of people would recommend this car to a friend</span>
            </div>
          )}
          {commonProblems && (
            <div className={`${cssPrefix}__owner-summary--common-container`}>
              {commonTitle && (
                <Heading size={4} extraClassNames={{ [`${cssPrefix}__owner-summary--section-title`]: true }}>{commonTitle}</Heading>
              )}
              <div className={`${cssPrefix}__owner-summary--common-problems`}>{commonProblems}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

/**
* Valid Props
*/
OwnerSummary.propTypes = {
  recommendationPercent: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  ratingsTitle: PropTypes.string,
  commonTitle: PropTypes.string,
  commonProblems: PropTypes.node,
  loadStyles: PropTypes.bool,
  extraClassNames: PropTypes.shape(),
  context: PropTypes.shape(),
};

/**
* Default Props
*/
OwnerSummary.defaultProps = {
  recommendationPercent: null,
  ratingsTitle: 'Owner Reviews',
  commonTitle: 'Common Problems',
  commonProblems: null,
  context: {},
  loadStyles: true,
  extraClassNames: {},
};

export default withPolaris(OwnerSummary);
