import React from 'react';
import PropTypes from 'prop-types';
import { getPodcast } from '@autovia-uk/polaris-components/sharedPartials/getPodcast';
import { PrimaryMedia } from '@autovia-uk/polaris-components/components/molecules/PrimaryMedia';

export const getPrimaryMedia = (primaryMedia, extraClassNames) => {
  if (!primaryMedia) {
    return false;
  }

  if (primaryMedia && primaryMedia.socialBlockType && primaryMedia.socialBlockType === 'SOCIAL_EMBED') {
    const embedData = JSON.parse(primaryMedia.data);

    // Handle podcast
    if (embedData && embedData.provider_name && embedData.provider_name === 'SoundCloud') {
      // get iframe src
      const iframeURLRegex = new RegExp('src="(.*?)"', 'gm');
      const iframeURLMatch = iframeURLRegex.exec(embedData.html);

      if (!iframeURLMatch || !iframeURLMatch[1]) {
        return false;
      }

      const podcastData = {
        podcastUrl: iframeURLMatch[1],
        title: '',
        excerpt: '',
        viewMoreLabel: '',
        viewMoreUrl: '',
      };

      return getPodcast(podcastData);
    }

    // Handle embed data
    if (embedData && embedData.__data) {
      // eslint-disable-next-line no-param-reassign
      primaryMedia = {
        mediaType: 'SocialEmbed',
        provider: embedData.__data.type,
        url: embedData.__data.url,
        socialEmbed: '',
      };
    }
  }

  let lazyLoad = true;
  if (primaryMedia || primaryMedia?.page?.images[0]) {
    lazyLoad = false;
  }

  return (
    <PrimaryMedia
      {...primaryMedia}
      extraClassNames={{ [`${extraClassNames}`]: true }}
      mediaLazyLoad={lazyLoad}
    />
  );
};

getPrimaryMedia.propTypes = {
  primaryMedia: PropTypes.shape({}).isRequired,
  extraClassNames: PropTypes.string.isRequired,
};
