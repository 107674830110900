import React from 'react';
import PropTypes from 'prop-types';
import { contentBlocksToText } from 'polaris-coreweb/exports';
import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading';
import { SimpleSearch } from '@autovia-uk/polaris-components/components/molecules/SimpleSearch';
import { getArticleGroup } from '@autovia-uk/polaris-components/sharedPartials/getArticleGroup';
import Helmet from '@autovia-uk/polaris-components/sharedHelpers/getHelmetForNotFound';

import { BaseComponent } from 'Templates';

import 'Styles/components/templates/_NotFound.scss';

const NotFound = ({
  layoutData: { mostPopular },
}) => (
  <>
    <div className="polaris__body polaris__not-found--template">
      <main className="polaris__main" id="main">
        <div className="polaris__core-content polaris__content -body-copy -center">
          <Heading size={2}>
            Sorry! This page cannot be found
          </Heading>

          <p>
            It looks like this page has moved somewhere else but you can try searching for what you are looking for
          </p>

          <SimpleSearch
            label=""
            placeholder="Search..."
            url="/search"
            extraClassNames={{
              '-with-filters': true,
            }}
          />
        </div>

        <div className="polaris__core-content polaris__content">
          {
            getArticleGroup({
              title: 'Most Popular',
              content: mostPopular.map(item => ({ ...item, excerpt: contentBlocksToText(item.excerpt) })),
              articleCardProps: {
                datePosition: 'meta',
                excerpt: null,
                isHeading: false,
              },
              articlesPerRow: 4,
            })
          }
        </div>
      </main>
    </div>
  </>
);

NotFound.propTypes = {
  layoutData: PropTypes.shape({
    mostPopular: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
};

const ComposedWithBaseComponent = props => (
  <BaseComponent {...props}>
    <Helmet {...props} />
    <NotFound {...props} />
  </BaseComponent>
);

export default ComposedWithBaseComponent;
