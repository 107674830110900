import React from 'react';
import { compose } from 'recompose';
import { getPaginationLimit } from '@autovia-uk/polaris-components/sharedPartials/getPaginationLimit';
import { BaseComponent } from 'Templates';
import {
  withJsonLD,
  withPreloadPrimaryImage,
} from 'Protons';
import Helmet from '@autovia-uk/polaris-components/sharedHelpers/getHelmetForIndexTemplate';
import { withPagination } from 'polaris-coreweb/exports';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import { IndexQueryPagination as query } from './IndexQuery.graphql';

import { IndexTemplate } from './IndexTemplate';

const Composed = compose(
  withPagination({ query, queryVars: getPaginationLimit }),
  withJsonLD(Helmet),
  withPolaris,
  withPreloadPrimaryImage,
)(IndexTemplate);

const ComposedWithBaseComponent = props => (
  <BaseComponent {...props}>
    <Composed {...props} />
  </BaseComponent>
);

export default ComposedWithBaseComponent;
