/**
 * @render react
 * @name OwnerReviewModal
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import { Button } from '@autovia-uk/polaris-components/components/atoms/Button';
import ReactModal from 'react-modal';
import './_style.scss';

/**
 * OwnerReviewModal component
 *
 * @param {Object} props
 * @param {Object} modalOpen - Determines whether the OwnerReviewModal is showing
 * @param {Object} toggleModal - Function to show/close the toggleModal
 * @param {Object} headerContent - Content to be shown in the header
 * @param {Object} bodyContent - Content to be shown in the body
 * @param {Object} footerContent - Content to be shown in the footer
 * @param {Object} props.context - Extra information coming from Polaris provider
 */

const OwnerReviewModal = (
  {
    modalOpen,
    toggleModal,
    headerContent,
    bodyContent,
    footerContent,
    context: { cssPrefix },
  },
) => {
  // To prevent errors from server-side rendering
  // For more information view: https://github.com/reactjs/react-modal/issues/580
  if (typeof window !== 'undefined') {
    ReactModal.setAppElement('body');
  }

  return (
    <ReactModal
      isOpen={modalOpen}
      onClose={() => toggleModal(false)}
      className={`${cssPrefix}__owner-form--modal`}
      overlayClassName={`${cssPrefix}__modal--overlay`}
    >
      {headerContent}
      {bodyContent}
      {footerContent}
      <Button extraClassNames={{ [`${cssPrefix}__owner-form--modal--close`]: true }} onClick={() => toggleModal(false)} />
    </ReactModal>
  );
};

/**
 * Valid props
 */
OwnerReviewModal.propTypes = {
  modalOpen: PropTypes.bool,
  headerContent: PropTypes.element,
  bodyContent: PropTypes.element,
  footerContent: PropTypes.element,
  toggleModal: PropTypes.func,
  context: PropTypes.shape({
    cssPrefix: PropTypes.string,
  }),
};

/**
 * Default props
 */
OwnerReviewModal.defaultProps = {
  modalOpen: false,
  headerContent: <></>,
  bodyContent: <></>,
  footerContent: <></>,
  toggleModal: null,
  context: {},
};

export default withPolaris(OwnerReviewModal);
