import React from 'react';
import get from 'lodash.get';
import { Ad } from '@autovia-uk/polaris-components/components/atoms/Ad';
import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading';

import { getArticleGroup } from '@autovia-uk/polaris-components/sharedPartials/getArticleGroup';
import { getBody } from '@autovia-uk/polaris-components/sharedPartials/getBody';
import { getBreadcrumbs } from '@autovia-uk/polaris-components/sharedPartials/getBreadcrumbs';
import { getNextPagesForArticleAndReview } from '@autovia-uk/polaris-components/sharedPartials/getNextPagesForArticleAndReview';
import { getNextSteps } from 'SharedPartialsLocal/getNextSteps';
import { getOwnerReviewSummary } from 'SharedPartialsLocal/getOwnerReviewSummary';
import { getOwnerReviewBlocks } from 'SharedPartialsLocal/getOwnerReviewBlocks';
import { getPostMeta } from 'SharedPartialsLocal/getPostMeta';
import { getRelatedContent } from '@autovia-uk/polaris-components/sharedHelpers/getRelatedContent';
import { getOwnerReviewsRatingCard } from 'SharedPartialsLocal/getOwnerReviewsRatingCard';
import { getReviewMultipageNavigation } from '@autovia-uk/polaris-components/sharedPartials/getReviewMultipageNavigation';
import { getSocial } from '@autovia-uk/polaris-components/sharedPartials/getSocial';
import { getTags } from '@autovia-uk/polaris-components/sharedPartials/getTags';
import { getWhichIsBest } from '@autovia-uk/polaris-components/sharedPartials/getWhichIsBest';

import { getArticleGroupContent } from '@autovia-uk/polaris-components/sharedHelpers/getArticleGroupContent';
import { getMostPopularArticleGroup } from '@autovia-uk/polaris-components/sharedHelpers/getMostPopularArticleGroup';
import { getSiteBlockGroup } from 'Helpers/getSiteBlockGroup';
import { getRightSideAdConfig } from '@autovia-uk/polaris-components/sharedHelpers/getRightSideAdConfig';

import { ownerReviewPropsShape } from './props-shape';

import 'Styles/components/templates/_Article.scss';

const OwnerReview = (props) => {
  const {
    config: {
      siteUrl,
    },
    layoutData: {
      page: {
        title,
        url,
        subtitle,
        breadcrumbs,
        cbOwnerReview,
        cbOwnerReviewSummary: reviewSummary,
        kicker,
        associatedContent,
        specsPath,
        isSponsored,
        tags,
        hideTitle,
      },
      page,
    },
  } = props;

  const multipage = getRelatedContent({ related: associatedContent, key: 'relatedPages' });
  const reportCard = getOwnerReviewsRatingCard({ props });
  const nextStepsComponent = getNextSteps({ props: page });
  const vehicleBests = page && get(page, 'vehicleBests.vehicleWhichIsBestVerdict', null);
  const whichIsBestComponent = getWhichIsBest({
    vehicleBests,
    title: 'Which Is Best?',
    specsPath,
    extraClassName: {
      '-no-bottom-border': true,
      '-review': true,
    },
  });

  const ownerReviewSummary = getOwnerReviewSummary({ reviewSummary });
  const ownerReviewBlocks = getOwnerReviewBlocks({ ownerReviews: cbOwnerReview });

  return (
    <>
      <div className="polaris__body">
        <main className="polaris__main" id="main">
          <div className="polaris__core-content polaris__content">
            { getBreadcrumbs({ breadcrumbs, isSponsored, kicker }) }
            {!hideTitle && (
              <Heading size={1} extraClassNames={{ '-content-title': true }}>
                {title}
              </Heading>
            )}
            <Heading size={2} extraClassNames={{ '-content-subtitle': true }}>
              {subtitle}
            </Heading>
            <div className="polaris__simple-grid">
              <div className="polaris__simple-grid--main">
                {getPostMeta(page)}
              </div>
            </div>
          </div>

          <div className="polaris__core-content polaris__content">
            {getReviewMultipageNavigation({ multipage, currentUrl: url })}
          </div>

          <div className="polaris__core-content polaris__content">
            {reportCard}
          </div>

          <div className="polaris__core-content polaris__content polaris__simple-grid -body-copy">

            <div className="polaris__simple-grid--aside">
              <Ad {...getRightSideAdConfig(1).props} />
            </div>

            <div className="polaris__simple-grid--main">
              {ownerReviewSummary}
              <div>
                <p className="polaris__review-block-title">
                  {'Review '}
                  <span className="polaris__accent-text">1</span>
                  {' of '}
                  <span className="polaris__accent-text">{ownerReviewBlocks.length}</span>
                </p>
              </div>
              {getBody({
                bodyInjected: ownerReviewBlocks,
                template: 'ownerReview',
                disableAdInjections: true,
                layoutType: 'ownerReview',
              })}
            </div>

            <div className="polaris__simple-grid--main">
              {nextStepsComponent}
              {multipage && whichIsBestComponent}
              {getNextPagesForArticleAndReview(multipage, url, 'Continue reading')}

              <div className="-tags-social">
                {getTags({
                  tags,
                  checkTagsCondition: false,
                  extraClassNames: { '-article': true },
                })}
                {getSocial(siteUrl, url, title, { '-article': true })}
              </div>

            </div>

          </div>

        </main>
      </div>
      <div className="polaris__core-content polaris__content">
        {getArticleGroup({
          title: 'Recommended',
          content: getArticleGroupContent({ related: associatedContent, key: 'recommended' }),
          articlesPerRow: 4,
          articleCardProps: {
            excerpt: null, datePosition: 'meta', rating: 5, showRating: true,
          },
        })}
        { getMostPopularArticleGroup({ associatedContent, nativeAd: 'native_article' }) }
        {getSiteBlockGroup({
          associatedContent,
          title: 'Tips & advice',
          articlesPerRow: 4,
          articleCardProps: {
            excerpt: null, datePosition: 'meta', headingSize: 4,
          },
        })}
        {getSiteBlockGroup({
          associatedContent,
          title: 'Best cars',
          articlesPerRow: 4,
          articleCardProps: {
            excerpt: null, datePosition: 'meta', headingSize: 4,
          },
        })}
      </div>
    </>
  );
};

OwnerReview.propTypes = {
  ...ownerReviewPropsShape,
};

OwnerReview.defaultProps = {};

export { OwnerReview };
