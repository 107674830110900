import React from 'react';
import classNames from 'classnames';
import { Ad } from '@autovia-uk/polaris-components/components/atoms/Ad';
import { ArticleGroup } from '@autovia-uk/polaris-components/components/organisms/ArticleGroup';
import { Hero } from '@autovia-uk/polaris-components/components/organisms/Hero';
import { getNativeAd } from '@autovia-uk/polaris-components/sharedPartials/getNativeAd';
import { getNewsletter } from '@autovia-uk/polaris-components/sharedPartials/getNewsletter';
import { getProductSelector } from '@autovia-uk/polaris-components/sharedPartials/getProductSelector';
import { getArticleContent } from '@autovia-uk/polaris-components/sharedHelpers/getArticleContent';
import { HtmlSafe } from 'polaris-coreweb/exports';
import globalConfig from 'Config/config.json';

export const getHomeArticleGroup = (contentData, overrideType = '') => {
  const { type, content, clientLogos } = contentData;

  const { ctaUrl } = contentData;
  let { ctaLabel, layoutType, title } = contentData;
  let articleCardStyle = 'default';
  let articleCardProps = {
    datePosition: 'meta',
    excerpt: null,
    showRating: true,
  };
  let articlesPerRow = 5;
  let ctaPosition = 'top';
  let extraClassNames = {};
  let showIndex = false;
  let hideImageMinimalIndex = 4;
  let image = {};
  let sponsorText = '';
  const mobileSize = globalConfig?.breakpoints?.mobile[0]?.max;
  let isMobile = false;
  if (typeof window !== 'undefined') isMobile = window?.innerWidth <= mobileSize;

  let contentItems = content.map(getArticleContent);

  if (!contentItems.length) {
    return null;
  }

  if (overrideType) {
    layoutType = overrideType;
  }

  switch (layoutType) {
    case 'Five Up Row':
      articlesPerRow = 5;
      extraClassNames = {
        '-border-bottom': true,
      };
      break;
    case 'Row':
      articlesPerRow = 4;
      extraClassNames = {
        '-border-bottom': true,
      };
      break;
    case 'Row with Dark Title':
      articlesPerRow = 4;
      ctaLabel = 'View All';
      extraClassNames = {
        '-alt-header': true,
        '-border-bottom': true,
      };
      break;
    case '6 + 1 Featured':
      articlesPerRow = 5;
      extraClassNames = {
        '-latest': true,
      };
      contentItems = contentItems.slice(0, 7);
      if (contentItems[0]) {
        contentItems[0].image.lazyLoad = false;
        contentItems[0].image.isLCPCandidate = true;
      }
      break;
    case 'Two Up Stacked':
      articlesPerRow = 2;
      extraClassNames = {
        '-two-up': true,
      };
      articleCardProps = {
        excerpt: null,
      };
      ctaPosition = 'bottom';
      break;
    case 'Double List':
      articlesPerRow = 1;
      articleCardStyle = 'side';
      articleCardProps = {
        datePosition: 'meta',
      };
      extraClassNames = {
        '-alt-side': true,
      };
      break;
    case 'Feature and Short List':
      articleCardStyle = 'side';
      articleCardProps = {
        datePosition: 'meta',
      };
      articlesPerRow = 1;
      extraClassNames = {
        '-alt-featured': true,
      };
      break;
    default:
      break;
  }

  switch (type) {
    case 'mostPopular':
      title = 'Most Popular';
      showIndex = true;
      extraClassNames['-home-popular'] = true;
      extraClassNames['-with-index'] = true;
      articlesPerRow = 4;
      articleCardStyle = 'minimal';
      hideImageMinimalIndex = 3;
      break;
    default:
      break;
  }

  if (title === 'Resources') {
    extraClassNames['-resources'] = true;
  }

  if (clientLogos && clientLogos.length && Array.isArray(clientLogos) && clientLogos[0].src) {
    image = {
      src: clientLogos[0].src,
      alt: clientLogos[0].alt || '',
    };
    extraClassNames = {};
    articleCardProps = {
      excerpt: null,
      datePosition: 'meta',
    };
    sponsorText = '';
  }

  return (
    <ArticleGroup
      articleCardProps={articleCardProps}
      articleCardStyle={articleCardStyle}
      articlesPerRow={articlesPerRow}
      content={contentItems}
      ctaLabel={ctaLabel}
      ctaPosition={isMobile ? 'bottom' : ctaPosition}
      ctaUrl={ctaUrl}
      extraClassNames={extraClassNames}
      hideImageMinimalIndex={hideImageMinimalIndex}
      image={image}
      showIndex={showIndex}
      sponsorText={sponsorText}
      title={title || ''}
    />
  );
};

export const getHomeContentBlocks = (associatedContent, config) => {
  const {
    newsletterConfig,
  } = config;

  if (!associatedContent || !associatedContent.length
    || !Array.isArray(associatedContent)) {
    return false;
  }

  const components = [];

  let adIndex = 1;
  let isNativeAdInjected = false;

  const injectNativeAdToContent = (content) => {
    if (isNativeAdInjected) {
      return content;
    }

    const nativeAd = {
      component: [
        <React.Fragment key="native_home">
          {getNativeAd({
            extraClassNames: {
              '-native-home': true,
            },
            id: 'native_home',
            targeting: {
              position: 'native_home',
              placement: 'native_home',
              refresh: 'no',
            },
          })}
        </React.Fragment>,
      ],
    };
    isNativeAdInjected = true;
    content.splice(-1, 1, nativeAd);

    return content;
  };

  for (let i = 0; i < associatedContent.length; i += 1) {
    const { layoutType, type } = associatedContent[i];

    let typeExist = false;

    const blockContentClass = 'polaris__core-content polaris__content';

    switch (type) {
      case 'adBlock':
        // if top ad is enabled, then skip the first ad block
        // i represent the index of the all associated content blocks
        // adIndex represent the index of the ad block in the page
        if (associatedContent[i + 1] && associatedContent[i + 1].type === 'makemodelfinder') {
          adIndex += 1;
          // eslint-disable-next-line no-continue
          continue;
        }
        components.push((
          <div
            className={classNames({
              polaris__content: true,
              '-homepageAdBlock': true,
              [`-homepageAdBlock-${adIndex}`]: true,
              'polaris__below-header-ad-wrapper': i === 0 && adIndex === 1,
            })}
            key={`adBlock-${adIndex}`}
          >
            <Ad
              extraClassNames={{
                '-full-width': true,
              }}
              id={`refresh-inline_${adIndex}`}
              isSkippable
              isSpaceReserved
              targeting={{
                position: `inline_${adIndex}`,
                placement: `inline_${adIndex}`,
                refresh: 'yes',
              }}
              type="slot-1"
            />
          </div>
        ));
        adIndex += 1;
        typeExist = true;
        break;
      case 'newsLetter':
        components.push((
          <div className={`${blockContentClass} polaris__newsletter-subscription`} key={`newsLetter-${i}`}>
            <div className="polaris__newsletter-subscription--content">
              {getNewsletter(
                {
                  props: associatedContent[i],
                  newsletterConfig,
                  additionalProps: { extraClassNames: { '-home': true } },
                },
              )}
            </div>
          </div>
        ));
        typeExist = true;
        break;
      case 'mostPopular': {
        const content = [...associatedContent[i].content].slice(0, 8);

        components.push((
          <div className={blockContentClass} key={`most-popular-${i}`}>
            {getHomeArticleGroup({ ...associatedContent[i], content })}
          </div>
        ));

        typeExist = true;
        break;
      }
      case 'makemodelfinder': {
        const hasBlockImage = (associatedContent[i].image && associatedContent[i].image.src);
        const hasTopAd = associatedContent[i - 1] && associatedContent[i - 1].type === 'adBlock';

        components.push((
          <Hero
            showPrimaryMedia={!hasTopAd}
            extraClassNames={{
              '-homeHeroWithAdBlock': hasTopAd, // add a class only if there is a top ad enabled
            }}
            key={`hero-${i}`}
            alt={hasBlockImage ? associatedContent[i].image.alt : ''}
            mediaLazyLoad={false}
            mediaType={hasBlockImage ? 'Image' : null}
            mediaSize="hero-feature"
            src={hasBlockImage ? associatedContent[i].image.src : ''}
            subtitle={associatedContent[i].subtitle ? associatedContent[i].subtitle : 'to finding your perfect car'}
            title={associatedContent[i].title ? associatedContent[i].title : 'The complete guide'}
            adBlock={hasTopAd && (
              <div
                className={classNames({
                  polaris__content: true,
                  '-homepageAdBlock': true,
                  '-homepageAdBlock-1': true,
                  '-homeHeroAdBlock': true,
                  'polaris__below-header-ad-wrapper': true,
                })}
                key="adBlock-1"
              >
                <Ad
                  extraClassNames={{
                    '-full-width': true,
                  }}
                  id="refresh-inline_1"
                  isSpaceReserved
                  targeting={{
                    position: 'inline_1',
                    placement: 'inline_1',
                    refresh: 'yes',
                  }}
                  type="slot-1"
                />
              </div>
            )}
          >

            {getProductSelector({
              title: 'Choose a car',
              showCta: true,
              ctaLabel: 'Find a review',
              extraClassNames: {
                'polaris__hero--product-selector': true,
              },
            })}
          </Hero>
        ));
        typeExist = true;
        break;
      }
      case 'widgetBlock': {
        const { html } = associatedContent[i];

        if (html) {
          components.push((
            <HtmlSafe
              key={`most-popular-${i}`}
              html={html}
            />
          ));
        }

        typeExist = true;
        break;
      }
      default:
        break;
    }

    if (typeExist) {
      // eslint-disable-next-line no-continue
      continue;
    }

    const j = i + 1;
    switch (layoutType) {
      case 'Hero Feature':
        components.push((
          <div className={blockContentClass} key={`heroFeature-${i}`}>
            <div className="polaris__home--grid-side">
              {getHomeArticleGroup(associatedContent[i])}
              {associatedContent[j] && getHomeArticleGroup(associatedContent[j])}
            </div>
          </div>
        ));
        if (associatedContent[j]) {
          i += 1;
        }
        break;
      case 'Two Up Stacked':
        components.push((
          <div className={blockContentClass} key={`twoUpStacked-${i}`}>
            {getHomeArticleGroup(associatedContent[i])}
          </div>
        ));
        break;
      case '6 + 1 Featured':
      case 'Six + 1 featured': {
        const content = injectNativeAdToContent([...associatedContent[i].content].slice(0, 7));
        const associatedContentMaybeWithNativeAd = { ...associatedContent[i], content };

        components.push((
          <div className={blockContentClass} key={`six1featured-${i}`}>
            {getHomeArticleGroup(associatedContentMaybeWithNativeAd, '6 + 1 Featured')}
          </div>
        ));
        break;
      }
      case 'Row with Dark Title':
        components.push((
          <div className={blockContentClass} key={`rowDarkTitle-${i}`}>
            {getHomeArticleGroup(associatedContent[i], 'Row with Dark Title')}
          </div>
        ));
        break;
      case 'Double List': {
        components.push((
          <div className={blockContentClass} key={`doubleList-${i}`}>
            <div className="polaris__grid -with-columns-2">
              {getHomeArticleGroup(associatedContent[i], 'Double List')}
              {associatedContent[j] && getHomeArticleGroup(associatedContent[j], 'Double List')}
            </div>
          </div>
        ));
        if (associatedContent[j]) {
          i += 1;
        }
        break;
      }
      case 'Five Up Row':
      case 'Row': {
        const content = injectNativeAdToContent([...associatedContent[i].content]);
        const associatedContentMaybeWithNativeAd = { ...associatedContent[i], content };

        components.push((
          <div className={blockContentClass} key={`row-${i}`}>
            {getHomeArticleGroup(associatedContentMaybeWithNativeAd)}
          </div>
        ));

        break;
      }
      default:
        components.push((
          <div className={blockContentClass} key={`default-${i}`}>
            {getHomeArticleGroup(associatedContent[i])}
          </div>
        ));
        break;
    }
  }

  return components;
};
