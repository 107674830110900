/**
 * @render react
 * @name OwnerReviewContentCard
*/

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import { Rating } from '@autovia-uk/polaris-components/components/molecules/Rating';
import './_style.scss';

/**
 * OwnerReviewContentCard component
 *
 * @param {array} reviewDetails - Array of objects with the following keys: title, rating, description
 * @param {boolean} showStarRating - Whether to display the star rating
 * @param {boolean} showTextRating - Whether to display the text for rating
 * @param {Object} context - Extra information coming from Polaris provider.
 */
const OwnerReviewContentCard = ({
  reviewDetails,
  showStarRating,
  showTextRating,
  context: { cssPrefix },
}) => {
  const [reviewExpanded, setReviewExpanded] = useState(false);

  const closeReview = () => {
    setReviewExpanded(false);
  };

  const expandReview = () => {
    setReviewExpanded(true);
  };

  return (
    <div className={`${cssPrefix}__owner-review--reviews`}>
      <div className={`${cssPrefix}__owner-review--review-content`.concat(reviewExpanded ? '--open' : '--closed')}>
        {reviewDetails.map((review, index) => {
          const recommendationDescription = review.isRecommendation ? (
            <div className={`${cssPrefix}__owner-review--recommended`}>
              <p>
                {review.recommendation ? 'Yes' : 'No'}
                &nbsp;
                {review.recommendation ? <img src="/public/thumbs_up_green.svg" alt="Green thumbs up" /> : <img src="/public/thumbs_down_red.svg" alt="Red thumbs down" />}
              </p>
            </div>
          ) : null;
          return (
            <Rating
              showStarRating={showStarRating}
              showTextRating={showTextRating}
              showZero
              key={`review-details-${index}`}
              label={review.title}
              rating={review.rating}
              extraContent={recommendationDescription || review.description}
              extraClassNames={{ [`${cssPrefix}__owner-review--single`]: true }}
            />
          );
        })}
      </div>
      <button
        type="button"
        onClick={reviewExpanded ? closeReview : expandReview}
        className={`${cssPrefix}__owner-review--collapse`}
      >
        <p className={reviewExpanded ? `${cssPrefix}__owner-review--collapse-close` : `${cssPrefix}__owner-review--collapse-readmore`}>
          {reviewExpanded ? 'close' : 'Read more'}
          &nbsp;
          <img src={reviewExpanded ? '/public/circle_chevron_up_grey.svg' : '/public/circle_chevron_down_blue.svg'} alt="Chevron in circle" />
        </p>
      </button>
    </div>
  );
};

/**
 * Valid props
 */
OwnerReviewContentCard.propTypes = {
  reviewDetails: PropTypes.arrayOf(PropTypes.object),
  showStarRating: PropTypes.bool,
  showTextRating: PropTypes.bool,
  context: PropTypes.shape({
    cssPrefix: PropTypes.string,
  }),
};

/**
 * Default props
 */
OwnerReviewContentCard.defaultProps = {
  reviewDetails: [],
  showStarRating: true,
  showTextRating: true,
  context: {},
};

export default withPolaris(OwnerReviewContentCard);
