import {
  Article,
  Author,
  BasicPage,
  ErrorPage,
  Gallery,
  Home,
  Loading,
  NotFound,
  OwnerReview,
  Review,
  Search,
  Spec,
  SpecList,
  IndexTemplate,
} from 'Templates';

// if you are adding Templates also add in helpers/template.js
export const layouts = {
  NEWS: Article,
  ARTICLE: Article,
  AUTHOR_INDEX: Author,
  BASIC_PAGE: BasicPage,
  CB_OWNER_REVIEW: OwnerReview,
  GALLERY: Gallery,
  HOMEPAGE: Home,
  INDEX_PAGE: IndexTemplate,
  INDEX_ARTICLE_PAGE: Article,
  REVIEW: Review,
  PAGINATED_REVIEW_VERDICT: Review,
  PAGINATED_REVIEW: Review,
  SEARCH_INDEX: Search,
  TAXONOMY_INDEX: IndexTemplate,
  VEHICLE_SPECS: Spec,
  VEHICLE_SPECLIST: SpecList,
  error: ErrorPage,
  loading: Loading,
  notFound: NotFound,
};
