import React from 'react';
import { Loading } from '@autovia-uk/polaris-components/components/atoms/Loading';
import { Search } from '@autovia-uk/polaris-components/components/molecules/Search';
import { getBreadcrumbs } from '@autovia-uk/polaris-components/sharedPartials/getBreadcrumbs';
import { getLoadMore } from '@autovia-uk/polaris-components/sharedPartials/getLoadMore';
import { getRightSideAd } from '@autovia-uk/polaris-components/sharedPartials/getRightSideAd';
import { getSearchPageItems } from '@autovia-uk/polaris-components/sharedPartials/getSearchPageItems';
import { getFilters } from './filters';
import { getBlankMessage } from './functions';

import { searchPropsShape } from './props-shape';
import 'Styles/components/templates/_Search.scss';

const SearchTemplate = (props) => {
  const {
    layoutData: {
      page: {
        query: {
          keywords = '',
          filter = 'ALL',
        },
      },
    },
    config: {
      siteUrl,
    },
    pagination: {
      setPageRef,
      pages,
      isLoading: isLoadingProp,
      loadMore,
      hasMore,
      nextUrl,
    },
  } = props;
  const filters = getFilters();
  const filterIds = filters.map(({ id }) => id);
  let searchTimeout = null;

  /**
   * Search function
   *
   * @param {*} keywordsSearch
   * @param {*} filterSearch
   */
  const doSearch = (keywordsSearch, filterSearch) => {
    const cleanKeywords = keywordsSearch.trim();

    let url = '/search';

    if (cleanKeywords) {
      url += `/${encodeURIComponent(cleanKeywords)}`;
    }
    if (filterSearch && filterSearch !== 'ALL' && filterIds.includes(filterSearch)) {
      if (!cleanKeywords) {
        url += '/'; // Add extra slash for an empty search query.
      }
      url += `/filter/${filterSearch.toLowerCase()}`;
    }
    url += '/';

    window.location.href = url;
  };

  /**
   * Debounce search
   *
   * @param {*} keywordsSearch
   * @param {*} filterSearch
   * @param debounce
   */
  const debounceSearch = (keywordsSearch, filterSearch, debounce = true) => {
    if (debounce) {
      clearTimeout(searchTimeout);
      searchTimeout = setTimeout(doSearch, 1200, keywordsSearch, filterSearch);
    } else {
      doSearch(keywordsSearch, filterSearch);
    }
  };

  const isSearching = !Object.keys(pages).length && isLoadingProp;
  const isLoading = Object.keys(pages).length > 0 && isLoadingProp;
  const resultPages = Object.entries(pages).length > 0 ? pages : { 0: [] }; // always match initial pages structure from withPagination
  const loadMoreParams = {
    label: isLoading ? 'Loading...' : 'Load More',
    loadMore,
    isLoading,
    extraClassNames: {
      'polaris__load-more--small': true,
    },
    href: nextUrl,
  };
  return (
    <div className="polaris__body">
      <div className="polaris__core-content polaris__content">
        {getBreadcrumbs({
          breadcrumbs: [
            {
              title: 'Home',
              url: siteUrl,
            },
            {
              title: 'Search',
              url: '',
            },
          ],
          title: 'Search Results',
          extraClassNames: {
            '-search-template': true,
          },
        })}
      </div>
      <div className="polaris__content--with-bg">
        <div className="polaris__content">
          <Search
            label=""
            placeholder="Search..."
            url="/search"
            searchTerm={keywords}
            filtersLabel="Filter by"
            currentFilter={filter || 'ALL'}
            onSubmit={debounceSearch}
            filters={filters}
            showFilters
            inputId="polaris__search--template-input"
          />
        </div>
      </div>
      <main className="polaris__core-content polaris__content polaris__simple-grid">
        <div className="polaris__simple-grid--main">
          {!keywords && (
            <p>
              Please enter a search term in the search box above.
            </p>
          )}
          <div data-previous="1" ref={setPageRef} />
          {isSearching && (
            <div className="loading-center">
              <Loading />
              <p>Loading search results</p>
            </div>
          )}
          {
            !isSearching && keywords
            && getSearchPageItems({ resultPages, setPageRef, blankMessage: getBlankMessage(keywords) })
          }
          {!isSearching && hasMore && getLoadMore(loadMoreParams)}
        </div>
        <div className="polaris__simple-grid--aside">
          {getRightSideAd({})}
        </div>
      </main>
    </div>
  );
};

SearchTemplate.propTypes = {
  ...searchPropsShape,
};

SearchTemplate.defaultProps = {
  history: null,
};

export { SearchTemplate };
