import React from 'react';
import get from 'lodash.get';
import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading';
import { ShortAuthorBio } from '@autovia-uk/polaris-components/components/molecules/ShortAuthorBio';
import injectionEngine from '@autovia-uk/injection-engine';
import rules from '@autovia-uk/injection-default-rules-set';
import { HtmlSafe } from 'polaris-coreweb/exports';
import { countBodyInjectedInlineAds } from '@autovia-uk/polaris-components/sharedHelpers/countBodyInjectedInlineAds';
import { getArticleGroupContent } from '@autovia-uk/polaris-components/sharedHelpers/getArticleGroupContent';
import { getIndexArticlePageList } from '@autovia-uk/polaris-components/sharedHelpers/getIndexArticlePageList';
import { getMostPopularArticleGroup } from '@autovia-uk/polaris-components/sharedHelpers/getMostPopularArticleGroup';
import { getRelatedContent } from '@autovia-uk/polaris-components/sharedHelpers/getRelatedContent';
import { getAfterContentAd } from '@autovia-uk/polaris-components/sharedPartials/getAfterContentAd';
import { getArticleGroup } from '@autovia-uk/polaris-components/sharedPartials/getArticleGroup';
import { getBody } from '@autovia-uk/polaris-components/sharedPartials/getBody';
import { getBreadcrumbs } from '@autovia-uk/polaris-components/sharedPartials/getBreadcrumbs';
import { getIndexArticlePageContent } from '@autovia-uk/polaris-components/sharedPartials/getIndexArticlePageContent';
import { getIndexArticlePageHeader } from '@autovia-uk/polaris-components/sharedPartials/getIndexArticlePageHeader';
import { getNextPagesForArticleAndReview } from '@autovia-uk/polaris-components/sharedPartials/getNextPagesForArticleAndReview';
import { getNextPagination } from 'SharedPartialsLocal/getNextPagination';
import { getNextSteps } from 'SharedPartialsLocal/getNextSteps';
import { getOwnerReviewsRatingCard } from 'SharedPartialsLocal/getOwnerReviewsRatingCard';
import { getPostMeta } from 'SharedPartialsLocal/getPostMeta';
import { getReviewMultipageNavigation } from '@autovia-uk/polaris-components/sharedPartials/getReviewMultipageNavigation';
import { getReviewProCon } from '@autovia-uk/polaris-components/sharedPartials/getReviewProCon';
import { getSocial } from '@autovia-uk/polaris-components/sharedPartials/getSocial';
import { getSponsorInfo } from '@autovia-uk/polaris-components/sharedPartials/getSponsorInfo';
import { getTags } from '@autovia-uk/polaris-components/sharedPartials/getTags';
import { getWhichIsBest } from '@autovia-uk/polaris-components/sharedPartials/getWhichIsBest';
import { getSiteBlockGroup } from 'Helpers/getSiteBlockGroup';
import { useViewportType } from '@autovia-uk/polaris-components/components/protons/ViewportChecker';

import { reviewPropsShape } from './props-shape';
import 'Styles/components/templates/_Article.scss';

const Review = (props) => {
  const {
    config: {
      newsletter: newsletterSettings,
      breakpoints,
      globalSettings: {
        newsletterLink,
        promoBoxSettings,
        adSettings,
        adSettings: {
          afterContentDesktopAds,
          afterContentMobileAds,
        },
      },
      siteUrl,
    },
    layoutData: {
      page: {
        authors,
        title,
        url,
        subtitle,
        breadcrumbs,
        kicker,
        isSponsored,
        body,
        metaData,
        monetising,
        associatedContent,
        sideContent,
        specsPath,
        sponsor,
        filters,
        showFilters,
        tags,
        hideTitle,
      },
      page,
    },
  } = props;

  const { isMobile } = useViewportType({
    breakpointsMobile: breakpoints.mobile,
    breakpointsDesktop: breakpoints.desktop,
  });

  const indexData = getIndexArticlePageList(associatedContent);
  const isIndexArticlePage = indexData !== false;
  const multipage = getRelatedContent({ related: associatedContent, key: 'relatedPages' });

  const promoBox = { ...promoBoxSettings, extraClassNames: { '-inline': true } };
  const newsletter = {
    props: {},
    portalNewsletterUrl: newsletterLink,
    configNewsletter: newsletterSettings,
    additionalProps: { extraClassNames: { '-inline': true } },
  };

  let bodyInjected = injectionEngine({
    body,
    rules,
    props: {
      adSettings,
      breakpoints,
      metaData,
      monetising,
      newsletterConfig: newsletter,
      pageContentType: 'review',
      promoBoxSettings: promoBox,
      sideContent,
    },
  });

  bodyInjected = bodyInjected.map((item) => {
    if (item.type === 'SUBSCRIBE_NEWSLETTER') {
      return {
        ...item,
        ...{ type: 'SHOPWINDOW' },
      };
    }
    return { ...item };
  });

  const countInlineAds = countBodyInjectedInlineAds({
    bodyInjected,
    bodyBlockInlineAdDesktop: 'DESKTOP_INLINE_ADVERT',
    bodyBlockInlineAdMobile: 'MOBILE_INLINE_ADVERT',
  });

  const reportCard = getOwnerReviewsRatingCard({ props });
  const reviewProCon = getReviewProCon(props);

  const taxonomyFilters = Array.isArray(filters) ? filters.map(f => ({
    label: f.title,
    value: f.url,
  })) : null;

  const taxonomyFilterParams = {
    content: taxonomyFilters,
    currentPageUrl: url,
    label: 'Show me:',
    listModeOptions: {
      seeMoreLabel: 'See all',
      seeLessLabel: 'See less',
      icon: 'down',
    },
  };

  const nextStepsComponent = getNextSteps({ props: page });

  const vehicleBests = page && get(page, 'vehicleBests.vehicleWhichIsBestVerdict', null);
  const whichIsBestComponent = getWhichIsBest({
    vehicleBests,
    title: 'Which Is Best?',
    specsPath,
    extraClassName: {
      '-no-bottom-border': true,
      '-review': true,
    },
  });

  const shortAuthorBio = () => {
    if (!authors || !authors.length) return null;

    const author = authors[0];

    return (
      <ShortAuthorBio
        authorBio={<HtmlSafe html={author.shortBio || ''} />}
        authorLink={author.url}
        authorImage={author.image}
        authorName={author.name}
        authorRole={author.jobTitle}
      />
    );
  };

  return (
    <>
      <div className="polaris__body">
        <main className="polaris__main" id="main">
          <div className="polaris__core-content polaris__content">

            { getBreadcrumbs({ breadcrumbs, isSponsored, kicker }) }

            {!hideTitle && (
              <Heading size={1} extraClassNames={{ '-content-title': true }}>
                {title}
              </Heading>
            )}

            <Heading size={2} extraClassNames={{ '-content-subtitle': true }}>
              {subtitle}
            </Heading>

            <div className="polaris__simple-grid">
              <div className="polaris__simple-grid--main">
                {getPostMeta(page)}
              </div>
            </div>
          </div>

          <div className="polaris__core-content polaris__content">
            {getReviewMultipageNavigation({ multipage, currentUrl: url })}
          </div>

          <div className="polaris__core-content polaris__content">
            {reportCard}
          </div>

          <div className="polaris__content -body-copy">

            {getBody({
              bodyInjected: [reviewProCon, getSponsorInfo({ sponsor }), ...bodyInjected],
              template: 'review',
              isMobile,
            })}

            <div className="polaris__core-content polaris__core-content-last polaris__simple-grid--main">
              {getNextPagination(associatedContent, url)}

              {nextStepsComponent}

              {multipage && whichIsBestComponent}

              {getNextPagesForArticleAndReview(multipage, url, 'Continue reading')}

              <div className="-tags-social">
                {getTags({ tags, extraClassNames: { 'polaris__tags -article': true } })}
                {getSocial(siteUrl, url, title, { '-article': true })}
              </div>

              {shortAuthorBio()}
            </div>
          </div>
        </main>
      </div>

      {(afterContentDesktopAds || afterContentMobileAds) && (getAfterContentAd({ afterContentDesktopAds, afterContentMobileAds }))}

      <div className="polaris__content">
        <div className="polaris__core-content polaris__core-content-first">
          {getArticleGroup({
            title: 'Recommended',
            content: getArticleGroupContent({ related: associatedContent, key: 'recommended' }),
            articlesPerRow: 4,
            articleCardProps: {
              excerpt: null, datePosition: 'meta', headingSize: 4, showRating: true,
            },
            extraClassNames: { 'polaris__review--consider': true },
          })}
          {getArticleGroup({
            title: 'Have You Considered',
            content: getArticleGroupContent({ related: associatedContent, key: 'similarProducts' }),
            articlesPerRow: 4,
            articleCardProps: {
              excerpt: null, datePosition: 'meta', showRating: true,
            },
            extraClassNames: { 'polaris__review--consider': true },
          })}
          {getMostPopularArticleGroup({
            associatedContent,
            nativeAd: 'native_article',
            extraClassNames: { 'polaris__review--consider': true },
          })}
        </div>
        {isIndexArticlePage && (
        <div>
          <div className="polaris__core-content">
            {getIndexArticlePageHeader({
              indexData,
              propsData: props,
              showFilters: showFilters && taxonomyFilters !== null && taxonomyFilters.length > 0,
              taxonomyFilterParams,
            })}
          </div>
          {getIndexArticlePageContent(
            indexData,
            props,
            countInlineAds,
            isMobile,
          )}
        </div>
        )}
        <div className="polaris__core-content">
          {getSiteBlockGroup({
            associatedContent,
            title: 'Tips & advice',
            articlesPerRow: 4,
            articleCardProps: {
              excerpt: null, datePosition: 'meta', headingSize: 4,
            },
          })}
          {getSiteBlockGroup({
            associatedContent,
            title: 'Best cars',
            articlesPerRow: 4,
            articleCardProps: {
              excerpt: null, datePosition: 'meta', headingSize: 4,
            },
          })}
        </div>
      </div>
    </>
  );
};

Review.propTypes = {
  ...reviewPropsShape,
};

Review.defaultProps = {
};

export { Review };
