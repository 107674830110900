import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'polaris-coreweb/exports';

import { getPrimaryMediaPreloadLink } from 'SharedPartialsLocal/getPrimaryMediaPreloadLink';

const withPreloadPrimaryImage = (Component) => {
  const WithPreloadPrimaryImage = (props) => {
    const {
      layoutData: {
        page,
      },
      context: {
        imageSizesCallback,
      },
    } = props;

    return (
      <>
        <Helmet>
          {getPrimaryMediaPreloadLink(page, imageSizesCallback)}
        </Helmet>
        <Component {...props} />
      </>
    );
  };


  WithPreloadPrimaryImage.propTypes = {
    layoutData: PropTypes.shape({
      page: PropTypes.shape({
        primaryMedia: PropTypes.shape(),
      }),
    }).isRequired,
    context: PropTypes.shape().isRequired,
  };

  WithPreloadPrimaryImage.displayName = 'withPreloadPrimaryImage()';

  return WithPreloadPrimaryImage;
};

export default withPreloadPrimaryImage;
