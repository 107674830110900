/**
 * @render react
 * @name OwnerReviewsRatingCard
 * pros={[{title:'good something'}]} cons={[{title:'bad something'}]} />
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import { Rating } from '@autovia-uk/polaris-components/components/molecules/Rating';
import { Price } from '@autovia-uk/polaris-components/components/atoms/Price';
import { Link } from '@autovia-uk/polaris-components/components/molecules/Link';
import { Button } from '@autovia-uk/polaris-components/components/atoms/Button';
import { VerdictExtra } from '@autovia-uk/polaris-components/components/molecules/VerdictExtra';
import { OwnerReviewFormModal } from '../OwnerReviewFormModal';
import './_style.scss';

/**
* OwnerReviewsRatingCard component
*
* @param {Object} props
* @param {Object} props.extraComponent - Extra component to be prepended to the report card. Can be a PrimaryMedia one
* @param {Object} props.priceOptions - Object that contains the props to pass to the Price component
* @param {string} props.prosIcon - Icon for pros
* @param {string} props.consIcon - Icon for cons
* @param {number|bool} props.rating - Rating number
* @param {Component} props.pros - sanitized text
* @param {Component} props.cons - sanitized text
* @param {Array} props.ctas - Array of objects with the following keys: title, url
* @param {bool} props.showProsCons - boolean to decide if pros & cons are shown (default: true)
* @param {string} props.priceCtaLabel - label for the price cta
* @param {string} props.priceCtaUrl - href for the price cta
* @param {boolean} loadStyles - Whether to apply the class names for styling
* @param {object} props.extraClassNames - Extra classes to be added to the component
* @param {number} props.ratingsRounding - rounding for ratings
* @param {Hook} props.useVRMDataFetch - hook used to fetch data about the vrm inputted
* @param {String} props.datalakeProxyUrl - url to send data to
* @param {String} props.sendDataToDatalakeApi - method used to send data to datalake
* @param {Array} props.extras - extra meta information for the report card. Array items are objects containing a label and a value
* @param {Object} props.context - Extra information coming from Polaris provider
*/

const OwnerReviewsRatingCard = (
  {
    extraComponent,
    ratings,
    pros,
    cons,
    starFill,
    starFillInactive,
    priceOptions,
    priceLabel,
    prosLabel,
    prosIcon,
    consLabel,
    consIcon,
    starsPath,
    starsViewBox,
    starsDimensions,
    starsSpacings,
    ctas,
    showProsCons,
    priceCtaLabel,
    priceCtaUrl,
    priceCtaUrlRel,
    loadStyles,
    extraClassNames,
    ratingsRounding,
    useVRMDataFetch,
    datalakeProxyUrl,
    sendDataToDatalakeApi,
    pageId,
    extras,
    context: { cssPrefix, ratingFillColor, ratingFillInactiveColor },
  },
) => {
  const [ownerReviewFormOpen, setOwnerReviewFormOpen] = useState(false);

  const submitButtonText = 'Submit your review';

  const handleSubmitReviewClick = () => {
    window.dataLayer.push({
      event: 'submit_review.button_click',
      'review.placement': 'rating_card',
      'button.text': submitButtonText,
    });

    setOwnerReviewFormOpen(true);
  };

  if (!pros && !cons && ratings.length === 0 && Object.keys(priceOptions).length === 0) {
    return false;
  }

  return (
    <div className={
      classNames({
        [`${cssPrefix}__or-rating-card`]: loadStyles,
        ...extraClassNames,
      })}
    >
      {extraComponent && (
        <div className={`${cssPrefix}__or-rating-card--component`}>
          {extraComponent}
        </div>
      )}
      <div className={
        classNames({
          [`${cssPrefix}__or-rating-card--meta`]: loadStyles,
          // indicate that container is column based depending on its content
          '-has-rows': (ratings.length !== 0 || priceOptions) && (pros || cons),
          ...extraClassNames,
        })}
      >
        { /* display column if at least one value exists */ }
        {(ratings.length !== 0 || priceOptions || extras.length !== 0) && (
          <div className={`${cssPrefix}__or-rating-card--row`}>
            <div className={`${cssPrefix}__or-rating-card--ratings`}>
              {ratings.map((rating, i) => {
                if (rating && rating.value !== undefined) {
                  return (
                    <div className={`${cssPrefix}__or-rating-card--ratings-container`} key={i}>
                      <Rating
                        label={rating.label}
                        rating={rating.value}
                        showTextRating={rating.showTextRating || false}
                        numberOfStars={5}
                        fill={starFill || ratingFillColor}
                        fillInactive={starFillInactive || ratingFillInactiveColor}
                        iconPath={starsPath}
                        iconViewBox={starsViewBox}
                        widgetDimensions={starsDimensions}
                        widgetSpacings={starsSpacings}
                        extraClassNames={{
                          [`${cssPrefix}__or-rating-card--rating`]: true,
                          [`${cssPrefix}__or-rating-card--section-container`]: true,
                        }}
                        extraContent={rating.extraContent}
                        rounding={ratingsRounding}
                      />
                      <div className={`${cssPrefix}__or-rating-card--ratings-cta-container`}>
                        {rating.ctaLabel ? (
                          <Link
                            href={rating.ctaUrl}
                            extraClassNames={{ [`${cssPrefix}__or-rating-card--rating-cta`]: true }}
                            rel={rating.ctaUrlRel ? rating.ctaUrlRel : ''}
                          >
                            {rating.ctaLabel}
                          </Link>
                        ) : (<div className={`${cssPrefix}__or-rating-card--cta-spaceholder`} />)}
                        {rating.label === 'Owners Rating' && (
                          <div className={`${cssPrefix}__or-rating-card--submit-rating-cta`}>
                            <Button
                              onClick={() => handleSubmitReviewClick()}
                              srLabel="Leave your review"
                            >
                              {submitButtonText}
                            </Button>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                }
                return null;
              })}
            </div>

            {priceOptions && (priceOptions.min > 0 || priceOptions.max > 0) && (
              <div className={`${cssPrefix}__or-rating-card--price-container ${cssPrefix}__or-rating-card--section-container`}>
                {priceLabel && (
                  <div className={`${cssPrefix}__or-rating-card--label`}>
                    {priceLabel}
                  </div>
                )}
                <div className={`${cssPrefix}__or-rating-card--price`}>
                  <Price {...priceOptions} />
                </div>
                {priceCtaLabel && (
                  <Link
                    href={priceCtaUrl}
                    extraClassNames={{ [`${cssPrefix}__or-rating-card--price-cta`]: true }}
                    rel={priceCtaUrlRel}
                  >
                    {priceCtaLabel}
                  </Link>
                )}
              </div>
            )}

            {extras.length !== 0 && (
              <div className={`${cssPrefix}__or-rating-card--extras`}>
                {extras.map((extra, index) => (
                  <div className={`${cssPrefix}__or-rating-card--extra ${cssPrefix}__or-rating-card--section-container`} key={index}>
                    <div className={`${cssPrefix}__or-rating-card--extra-label ${cssPrefix}__or-rating-card--label`}>
                      {extra.label}
                    </div>
                    <div className={`${cssPrefix}__or-rating-card--extra-value ${cssPrefix}__or-rating-card--value`}>
                      {extra.value}
                    </div>
                  </div>
                ))
              }
              </div>
            )}
          </div>
        )}

        { /* display column if at least one value exists */ }
        {((pros || cons) && showProsCons) && (
          <div className={`${cssPrefix}__or-rating-card--row`}>
            {pros && (
              <VerdictExtra
                label={prosLabel}
                icon={prosIcon}
                content={pros}
                extraClassNames={{
                  [`${cssPrefix}__or-rating-card--pros-container`]: true,
                  [`${cssPrefix}__or-rating-card--section-container`]: true,
                }}
              />
            )}

            {cons && (
              <VerdictExtra
                label={consLabel}
                icon={consIcon}
                content={cons}
                extraClassNames={{
                  [`${cssPrefix}__or-rating-card--cons-container`]: true,
                  [`${cssPrefix}__or-rating-card--section-container`]: true,
                }}
              />
            )}
          </div>
        )}

        {ctas.length > 0 && (
          <div className={`${cssPrefix}__or-rating-card--ctas`}>
            {ctas.map((cta, index) => (
              <Link
                href={cta.url}
                rel={cta.urlRel || ''}
                key={index}
                onClick={cta.onClick || undefined}
              >
                {cta.title}
              </Link>
            ))
            }
          </div>
        )}
      </div>
      <OwnerReviewFormModal
        modalOpen={ownerReviewFormOpen}
        setModalOpen={setOwnerReviewFormOpen}
        articleId={pageId}
        useVRMDataFetch={useVRMDataFetch}
        datalakeProxyUrl={datalakeProxyUrl}
        sendDataToDatalakeApi={sendDataToDatalakeApi}
      />
    </div>
  );
};

/**
* Valid props
*/
OwnerReviewsRatingCard.propTypes = {
  extraComponent: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.object,
    PropTypes.string,
  ]),
  ratings: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
    extraContent: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.object,
      PropTypes.bool,
    ]),
  })),
  pros: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.object,
    PropTypes.bool,
  ]),
  cons: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.object,
    PropTypes.bool,
  ]),
  starFill: PropTypes.string,
  starFillInactive: PropTypes.string,
  priceLabel: PropTypes.string,
  prosLabel: PropTypes.string,
  prosIcon: PropTypes.string,
  consLabel: PropTypes.string,
  consIcon: PropTypes.string,
  starsPath: PropTypes.string,
  starsViewBox: PropTypes.string,
  starsDimensions: PropTypes.string,
  starsSpacings: PropTypes.string,
  priceOptions: PropTypes.shape(),
  ctas: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  showProsCons: PropTypes.bool,
  priceCtaLabel: PropTypes.string,
  priceCtaUrl: PropTypes.string,
  priceCtaUrlRel: PropTypes.string,
  context: PropTypes.shape({
    cssPrefix: PropTypes.string,
    ratingFillColor: PropTypes.string,
    ratingFillInactiveColor: PropTypes.string,
  }),
  loadStyles: PropTypes.bool,
  extraClassNames: PropTypes.shape(),
  ratingsRounding: PropTypes.number,
  useVRMDataFetch: PropTypes.object,
  datalakeProxyUrl: PropTypes.string,
  pageId: PropTypes.string,
  sendDataToDatalakeApi: PropTypes.func,
  extras: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
      PropTypes.string,
    ]),
  ),
};

/**
* Default props
*/
OwnerReviewsRatingCard.defaultProps = {
  extraComponent: null,
  ratings: [],
  pros: false,
  cons: false,
  starFill: '',
  starFillInactive: '',
  priceLabel: 'Price',
  prosLabel: '➕',
  prosIcon: null,
  consLabel: '➖',
  consIcon: null,
  starsPath: '',
  starsViewBox: '',
  starsDimensions: '',
  starsSpacings: '',
  priceOptions: {},
  ctas: [],
  showProsCons: true,
  priceCtaUrl: '',
  priceCtaLabel: '',
  priceCtaUrlRel: '',
  context: {},
  loadStyles: true,
  extraClassNames: {},
  ratingsRounding: 0,
  useVRMDataFetch: {},
  datalakeProxyUrl: '',
  pageId: '',
  sendDataToDatalakeApi: null,
  extras: [],
};

export default withPolaris(OwnerReviewsRatingCard);
