import React from 'react';
import PropTypes from 'prop-types';

import { Price } from '@autovia-uk/polaris-components/components/atoms/Price';
import { Review } from '@autovia-uk/polaris-components/components/organisms/Review';

export const getReviewBlock = (props) => {
  const {
    reviewIndex,
    title,
    url,
    image,
    description,
    allReviewsLabel = 'Read reviews',
    allReviewsLabelExtraClassNames,
    allReviewsUrl,
    allReviewsWrapperExtraClassNames,
    ratings,
    extraClassNames = { '-content-body': true },
    showCustomRating = false,
    priceOptions = null,
    watchVideo,
  } = props;

  const priceComponent = () => {
    const { min = 0, max = 0 } = priceOptions || {};

    if (min === 0 && max === 0) return null;

    return (
      <p>
        <b>Price</b>
        :&nbsp;
        <Price {...priceOptions} />
      </p>
    );
  };

  return (
    <Review
      reviewIndex={reviewIndex || null}
      title={title || ''}
      image={image || null}
      description={description || ''}
      url={url || null}
      readMoreLabel=""
      allReviewsLabel={allReviewsUrl && allReviewsLabel ? allReviewsLabel : ''}
      allReviewsLabelExtraClassNames={allReviewsLabelExtraClassNames}
      allReviewsUrl={allReviewsUrl || null}
      allReviewsWrapperExtraClassNames={allReviewsWrapperExtraClassNames}
      ratings={ratings || null}
      showCustomRating={showCustomRating}
      extraClassNames={extraClassNames}
      watchVideo={watchVideo}
    >
      {priceComponent()}
    </Review>
  );
};

getReviewBlock.propTypes = {
  reviewIndex: PropTypes.number,
  title: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  description: PropTypes.string,
  allReviewsLabel: PropTypes.string,
  allReviewsLabelExtraClassNames: PropTypes.shape({
    className: PropTypes.string,
  }),
  allReviewsUrl: PropTypes.string,
  allReviewsWrapperExtraClassNames: PropTypes.shape({
    className: PropTypes.string,
  }),
  ratings: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
    rounding: PropTypes.number,
  })),
  extraClassNames: PropTypes.shape({
    '-content-body': PropTypes.bool,
  }),
  showCustomRating: PropTypes.bool,
  priceOptions: PropTypes.shape({
    min: PropTypes.number,
    max: PropTypes.number,
  }),
  watchVideo: PropTypes.shape({
    label: PropTypes.string,
    url: PropTypes.string,
    labelExtraClassNames: PropTypes.shape({
      className: PropTypes.string,
    }),
    wrapperExtraClassNames: PropTypes.shape({
      className: PropTypes.string,
    }),
  }),
};
