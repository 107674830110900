/**
 * Extracts content for the ArticleGroup component.
 * @param {Array} related Array of objects that have a type and content key.
 * @param {String} key    What key to look for. Defaults to most popular.
 * @param {String} titleKey    What title key to look for. Defaults to most popular.
 */
const getSiteBlockGroupContent = ({
  related,
  key = 'siteBlock',
  titleKey = 'Tips & advice',
}) => {
  const blocks = Array.isArray(related) ? related.filter(({ type }) => type === key) : [];
  const block = blocks.find(({ title }) => title === titleKey);
  const relatedContent = (block ? block.content : []).map(item => ({
    ...item,
    title: item.altTitle || item.title,
  }));

  return { block, relatedContent };
};

export default getSiteBlockGroupContent;
