/**
 * @render react
 * @name CompletedFormModal
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import { Button } from '@autovia-uk/polaris-components/components/atoms/Button';
import ReactModal from 'react-modal';
import './_style.scss';
import CompletedFormContent from 'Molecules/OwnerReviewForm/CompletedFormContent';

/**
* CompletedFormModal component
*
* @param {Object} props
* @param {Object} completedFormModalOpen
* @param {Object} toggleCompletedFormModal
* @param {Object} props.context - Extra information coming from Polaris provider
*/

const CompletedFormModal = (
  {
    completedFormModalOpen,
    toggleCompletedFormModal,
    context,
  },
) => {
  const { cssPrefix } = context;

  // To prevent errors from server-side rendering
  // For more information view: https://github.com/reactjs/react-modal/issues/580
  if (typeof window !== 'undefined') {
    ReactModal.setAppElement('body');
  }

  return (
    <ReactModal
      isOpen={completedFormModalOpen}
      onClose={toggleCompletedFormModal}
      className={`${cssPrefix}__completed-form`}
      overlayClassName={`${cssPrefix}__completed-form--overlay ${cssPrefix}__modal--overlay`}
    >
      <CompletedFormContent context={context} />
      <div className={`${cssPrefix}__completed-form--footer`}>
        <Button extraClassNames={{ [`${cssPrefix}__completed-form--close`]: true }} onClick={toggleCompletedFormModal}>
          {'close '}
          <img src="/public/cancel_navy.svg" alt="" />
        </Button>
      </div>
    </ReactModal>
  );
};

/**
* Valid props
*/
CompletedFormModal.propTypes = {
  completedFormModalOpen: PropTypes.bool,
  toggleCompletedFormModal: PropTypes.func,
  context: PropTypes.shape({
    cssPrefix: PropTypes.string,
  }),
};

/**
* Default props
*/
CompletedFormModal.defaultProps = {
  completedFormModalOpen: false,
  toggleCompletedFormModal: null,
  context: {},
};

export default withPolaris(CompletedFormModal);
