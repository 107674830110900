/**
 * @render react
 * @name Header
*/

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import { Button } from '@autovia-uk/polaris-components/components/atoms/Button';
import { Image } from '@autovia-uk/polaris-components/components/atoms/Image';
import { Link } from '@autovia-uk/polaris-components/components/molecules/Link';
import { Navigation } from '@autovia-uk/polaris-components/components/molecules/Navigation';
import { SimpleSearch } from '@autovia-uk/polaris-components/components/molecules/SimpleSearch';
import { getProductSelector } from '@autovia-uk/polaris-components/sharedPartials/getProductSelector';
import { getSkipLinkComponent } from '@autovia-uk/polaris-components/sharedPartials/getSkipLinkComponent';
import './_style.scss';

const Header = (props) => {
  const {
    showProductSelector,
    context: {
      cssPrefix,
      header: {
        logoImage,
        navigation,
      },
    },
    isLandingPage,
  } = props;

  /**
   * Header interaction with its component: navigation/main-menu and search
   * */

  // Create ref for our search container
  const searchRef = React.createRef();

  /**
   * Define default state of main menu button
   * Default: false (menu is hidden)
   * */
  const [isOpen, setOpen] = useState(false);

  /**
   * Define the default state of search button
   * default: false (search input is hidden)
   * */
  const [isSearchOpen, setSearchOpen] = useState(false);

  /**
   * Control appearance of the main menu on mobile (switch from false/true to true/false)
   * */
  const onClick = () => {
    setOpen(!isOpen);
    document.documentElement.classList.toggle('-menu-open');
  };

  /**
   * Control appearance of the search input field (switch from false/true to true/false)
   * Close navigation/main menu, if it is open
   * */
  const onSearchClick = () => {
    setSearchOpen(!isSearchOpen);
    setOpen(false);
  };

  /**
   * When search is opened/closed
   */
  useEffect(() => {
    if (typeof window !== 'undefined' && isSearchOpen) {
      if (searchRef && searchRef.current) {
        const inputEl = searchRef.current.querySelector('input[type="search"]');
        setTimeout(() => {
          inputEl.focus();
        }, 0);
      }
    }
  }, [isSearchOpen]);

  /**
   * Render function
   */
  return (
    <>
      {getSkipLinkComponent()}
      <header
        className={classNames({
          [`${cssPrefix}__header`]: true,
        })}
        id="header"
      >
        <div
          className={classNames({
            [`${cssPrefix}__header--inner`]: true,
            '-is-menu-open': isOpen,
            '-is-search-open': isSearchOpen,
          })}
        >
          <div
            className={classNames({
              [`${cssPrefix}__header--logo`]: true,
            })}
          >
            <Link href="/">
              <Image
                alt={logoImage.alt}
                src={logoImage.src}
                height={logoImage.height}
                width={logoImage.width}
                lazyLoad={false}
              />
            </Link>
          </div>

          {!isLandingPage && (
            <div
              className={classNames({
                [`${cssPrefix}__header--navigation-search`]: true,
              })}
            >
              {navigation && (
                <Navigation
                  id="polaris__top-menu"
                  content={navigation}
                  onButtonClick={onClick}
                  navigationClassName={`${cssPrefix}__header--navigation`}
                  buttonClassName={`${cssPrefix}__header--menu-button`}
                  subMenuIcon={`${cssPrefix}__ico-down-chevron`}
                  responsive
                />
              )}

              <div
                className={classNames({
                  [`${cssPrefix}__header--search`]: true,
                })}
                ref={searchRef}
              >
                <SimpleSearch
                  label=""
                  placeholder="What are you looking for"
                  url="/search"
                  submitLabel=""
                  onSubmit={onSearchClick}
                />
                <Button
                  type="button"
                  label=""
                  srLabel="Search"
                  onClick={onSearchClick}
                  extraClassNames={{
                    [`${cssPrefix}__header--search-button`]: true,
                  }}
                  variant=""
                />
              </div>
            </div>
          )}
        </div>

        {showProductSelector && !isLandingPage && (
          <div className={`${cssPrefix}__header--product-selector--wrapper`}>
            <div className={`${cssPrefix}__content ${cssPrefix}__content-padding`}>
              {getProductSelector({
                mainTitle: 'Helping you find your perfect car',
                title: 'Choose a car review',
                extraClassNames: {
                  [`${cssPrefix}__header--product-selector`]: true,
                },
              })}
            </div>
          </div>
        )}
      </header>
    </>
  );
};

/**
 * Valid prop types
 */
Header.propTypes = {
  context: PropTypes.shape().isRequired,
  showProductSelector: PropTypes.bool,
  isLandingPage: PropTypes.bool,
};

Header.defaultProps = {
  showProductSelector: true,
  isLandingPage: false,
};

export default withPolaris(Header);
