import React from 'react';
import { Loading as LoadingDots } from '@autovia-uk/polaris-components/components/atoms/Loading';
import { BaseComponent } from 'Templates';
import Helmet from '@autovia-uk/polaris-components/sharedHelpers/getHelmetForLoading';

import 'Styles/components/templates/_Loading.scss';

const Loading = () => (
  <div className="polaris__body">
    <main className="polaris__main" id="main">
      <div className="polaris__core-content polaris__content -body-copy -center">
        <LoadingDots />
      </div>
    </main>
  </div>
);

const ComposedWithBaseComponent = props => (
  <BaseComponent {...props}>
    <Helmet {...props} />
    <Loading {...props} />
  </BaseComponent>
);

export default ComposedWithBaseComponent;
