/**
 * @render react
 * @name VRMInput
 */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import { Input } from '@autovia-uk/polaris-components/components/atoms/Input';
import { Label } from '@autovia-uk/polaris-components/components/molecules/Label';
import './_style.scss';
import VRMConfirmation from './VRMConfirmation';
import { isUkNumberPlateValid } from './helpers/validation';

/**
 * VRMInput component
 *
 * @param {Object} props
 * @param {Object} label - Title label for input
 * @param {Object} helpLabel - Extra information/example to guide user
 * @param {Object} index - unique index for each input
 * @param {Object} valueSetter - method used to set value in state
 * @param {Object} setValueIsValid - method used to set value for validation in state
 * @param {Object} useVRMDataFetch - hook used to fetch data about the vrm inputted
 * @param {Object} props.context - Extra information coming from Polaris provider
 */

const VRMInput = (
  {
    label,
    helpLabel,
    index,
    valueSetter,
    setValueIsValid,
    useVRMDataFetch,
    context: { cssPrefix },
  },
) => {
  const [tempVrmValue, setTempVrmValue] = useState('');
  const [tempVrmValid, setTempVrmValid] = useState(false);
  const { state: ukVehicleDataFetchState, fetchData } = useVRMDataFetch;
  const [vehicleData, setVehicleData] = useState({
    manufacturer: '',
    model: '',
    bodyType: '',
    colour: '',
    engineSize: '',
    fuelType: '',
    transmission: '',
    year: '',
  });
  const [userConfirmationOpen, setUserConfirmationOpen] = useState(false);
  const [userConfirmation, setUserConfirmation] = useState(false);

  // Update local state var with data on any change to the ukVehicleDataFetchState
  useEffect(() => {
    if (tempVrmValue !== '') {
      if (ukVehicleDataFetchState.isError) {
        setValueIsValid(false);
        setTempVrmValid(false);
      }

      if (ukVehicleDataFetchState.isSuccess && ukVehicleDataFetchState.data) {
        if (ukVehicleDataFetchState.data.getUkvVehicleData && ukVehicleDataFetchState.data.getUkvVehicleData.error === null) {
          const {
            data: {
              getUkvVehicleData: {
                vehicleData: {
                  manufacturer,
                  model,
                  colour,
                  fuelType,
                  bodyType,
                  engineSize,
                  transmission,
                  year,
                },
              },
            },
          } = ukVehicleDataFetchState;

          setVehicleData({
            manufacturer,
            model,
            colour,
            fuelType,
            bodyType,
            engineSize,
            transmission,
            year,
          });
        } else {
          setValueIsValid(false);
          setTempVrmValid(false);
        }
      }
    }
  }, [ukVehicleDataFetchState.data]);

  // Check userConfirmation on open/close of vrm checking if tempVrmValue has a value
  useEffect(() => {
    if (tempVrmValue !== '' && !userConfirmationOpen) {
      if (userConfirmation) {
        valueSetter(tempVrmValue);
        setValueIsValid(true);
      } else {
        setTempVrmValid(false);
      }
    }
  }, [userConfirmationOpen]);

  // Trigger fetching of vrm data
  const getVehicleData = (vrmValue) => {
    const variables = { vrm: vrmValue };
    fetchData({ variables });
  };

  // On change, set timeout (to debounce) before querying
  useEffect(() => {
    const timer = tempVrmValue !== '' ? setTimeout(() => {
      setUserConfirmationOpen(true);

      if (tempVrmValue) {
        // validate using data about vrm
        getVehicleData(tempVrmValue);
      }
    }, 2000) : () => {};
    return () => clearTimeout(timer);
  }, [tempVrmValue]);

  const handleVRMChange = (event) => {
    setUserConfirmation(false);
    setValueIsValid(false);

    const inputValue = event.target !== undefined ? event.target.value : event.value;
    const valid = isUkNumberPlateValid(inputValue);

    setTempVrmValue(inputValue);
    setTempVrmValid(valid);


    if (!valid) {
      setValueIsValid(valid);
    }
  };

  // Determine what styles should be used
  const getInputClass = () => {
    if (!tempVrmValid && tempVrmValue !== '') {
      return `${cssPrefix}__vrm-input--input ${cssPrefix}__vrm-input--input-invalid`;
    }

    return `${cssPrefix}__vrm-input--input`;
  };

  const helpLabelClass = userConfirmationOpen ? `${cssPrefix}__vrm-input--label-help-hide` : `${cssPrefix}__vrm-input--label-help`;
  const errorClass = !userConfirmationOpen && vehicleData.manufacturer !== '' && !userConfirmation ? `${cssPrefix}__vrm-input--label-error` : `${cssPrefix}__vrm-input--label-error-hide`;

  return (
    <div className={`${cssPrefix}__vrm-input`}>
      <div className={`${cssPrefix}__vrm-input--form-input`}>
        <div className={`${cssPrefix}__vrm-input--label-container`}>
          <Label
            content={label}
            target={index}
            extraClassNames={{ [`${cssPrefix}__vrm-input--label`]: true }}
          />
        </div>
        <div>
          <Input
            id={index}
            required
            value={tempVrmValue}
            onChange={handleVRMChange}
            extraClassNames={{ [getInputClass()]: true }}
          />
        </div>
        <div className={`${cssPrefix}__vrm-input--label-container`}>
          <Label
            content={helpLabel}
            target={index}
            extraClassNames={{ [helpLabelClass]: true }}
          />
          <Label
            content="Please re-enter your registration number"
            target={index}
            extraClassNames={{ [errorClass]: true }}
          />
        </div>
      </div>
      <VRMConfirmation
        confOpen={userConfirmationOpen}
        setConfOpen={setUserConfirmationOpen}
        setUserConfirmation={setUserConfirmation}
        vehicleData={vehicleData}
        vrm={tempVrmValue}
        vrmValid={tempVrmValid}
      />
    </div>
  );
};

/**
 * Valid props
 */
VRMInput.propTypes = {
  label: PropTypes.string,
  helpLabel: PropTypes.string,
  index: PropTypes.string,
  valueSetter: PropTypes.func,
  setValueIsValid: PropTypes.func,
  useVRMDataFetch: PropTypes.object,
  context: PropTypes.shape({
    cssPrefix: PropTypes.string,
  }),
};

/**
 * Default props
 */
VRMInput.defaultProps = {
  label: 'Vehicle Registration Mark',
  helpLabel: '',
  index: '',
  valueSetter: null,
  setValueIsValid: null,
  useVRMDataFetch: {},
  context: {},
};

export default withPolaris(VRMInput);
