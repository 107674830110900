import React, { Fragment } from 'react';
import uniqid from 'uniqid';
import { Ad } from '@autovia-uk/polaris-components/components/atoms/Ad';
import { Collapsible } from '@autovia-uk/polaris-components/components/molecules/Collapsible';
import { Heading } from '@autovia-uk/polaris-components/components/molecules/Heading';
import { Table } from '@autovia-uk/polaris-components/components/organisms/Table';

import { vehicleSpecsPropsShape } from './props-shape';

const VehicleSpecs = ({
  vehicleSpecs,
}) => {
  const {
    summary,
    technicalData,
    standardEquipment,
  } = vehicleSpecs;

  const renderSummary = () => {
    const {
      doors,
      driveTrain,
      fuelDelivery,
      transmission,
      fuelType,
      CC,
      trim,
      price,
    } = summary;

    const formattedPrice = price ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '';

    return (
      <Table mode="swipe" key={uniqid('summary-')}>
        <table>
          <tbody>
            <tr>
              <td>Doors</td>
              <td>{doors}</td>
            </tr>
            <tr>
              <td>Drive Train</td>
              <td>{driveTrain}</td>
            </tr>
            <tr>
              <td>Fuel Delivery</td>
              <td>{fuelDelivery}</td>
            </tr>
            <tr>
              <td>Transmission</td>
              <td>{transmission}</td>
            </tr>
            <tr>
              <td>Fuel Type</td>
              <td>{fuelType}</td>
            </tr>
            <tr>
              <td>Fuel Delivery</td>
              <td>{fuelDelivery}</td>
            </tr>
            <tr>
              <td>CC</td>
              <td>{CC}</td>
            </tr>
            <tr>
              <td>Trim</td>
              <td>{trim}</td>
            </tr>
            <tr>
              <td>Price</td>
              <td className="polaris__table--price">{`£${formattedPrice}`}</td>
            </tr>
          </tbody>
        </table>
      </Table>
    );
  };

  const renderTechnicalData = () => technicalData
    .map(({ group: groupTitle, items }) => (
      <Fragment key={uniqid('technical-data-')}>
        <Heading size={2} extraClassNames={{ h3: true }}>{groupTitle}</Heading>
        <Table mode="stack">
          <table>
            <tbody>
              { items.map((item, index) => (
                <tr key={index}>
                  <td>{item.key}</td>
                  <td>{item.value}</td>
                </tr>
              )) }
            </tbody>
          </table>
        </Table>
      </Fragment>
    ));

  const renderEquipmentData = () => standardEquipment
    .map(({ group: groupTitle, items }) => (
      <Fragment key={uniqid('standard-equipment-')}>
        <Heading size={2} extraClassNames={{ h3: true }}>{groupTitle}</Heading>
        <ul>
          { items.map((item, index) => (
            <li key={index}>{item}</li>
          )) }
        </ul>
      </Fragment>
    ));

  return (
    <>
      <div className="polaris__simple-grid--main">
        <Collapsible
          key={uniqid()}
          title="Summary"
          content={renderSummary()}
          isOpen
          iconOpen="polaris__ico-down-chevron"
          iconClose="polaris__ico-up-chevron"
        />

        <Collapsible
          key={uniqid()}
          title="Equipment"
          content={renderEquipmentData()}
          isOpen
          iconOpen="polaris__ico-down-chevron"
          iconClose="polaris__ico-up-chevron"
        />
      </div>

      <div className="polaris__simple-grid--full">
        <Ad
          key="refresh-inline_1"
          isSpaceReserved
          type="slot-1"
          id="refresh-inline_1"
        />
      </div>

      <div className="polaris__simple-grid-main">
        <Collapsible
          key={uniqid()}
          title="Technical"
          content={renderTechnicalData()}
          isOpen
          iconOpen="polaris__ico-down-chevron"
          iconClose="polaris__ico-up-chevron"
        />
      </div>
    </>
  );
};

VehicleSpecs.propTypes = {
  ...vehicleSpecsPropsShape,
};

VehicleSpecs.defaultProps = {
  vehicleSpecs: {
    technicalData: [],
    standardEquipment: [],
  },
};

export default VehicleSpecs;
