import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'polaris-coreweb/exports';
import { getAuthorsForJsonLd } from '@autovia-uk/polaris-components/sharedHelpers/getAuthorsForJsonLd';
import { getRating } from './getRating';

/**
 * Set Meta tag in the header
 * @returns {*}
 */
const getHelmetForOwnerReview = (props) => {
  const {
    layoutData: {
      page: pageData,
      page: {
        altTitle,
        authors: rawAuthors,
        teaserImage,
        date: dateModified,
        created: datePublished,
      },
    },
    metaData: {
      pageTitle: metaTitle,
      pageDescription: metaDescription,
    },
    dataLayer: {
      product,
      productFamily,
      make,
    },
    config: {
      siteUrl,
      branding: {
        logoPng: {
          src: logoImageSrc,
          height: logoImageHeight,
          width: logoImageWidth,
        },
      },
      globalSettings: {
        name: siteName,
      },
    },
  } = props;

  const authors = getAuthorsForJsonLd({ authors: rawAuthors, siteUrl, siteName });
  const image = teaserImage ? [teaserImage.src] : [];

  const {
    verdict,
    rating,
  } = pageData;
  const jsonld = {
    '@context': 'https://schema.org',
    '@type': 'Car',
    name: product || productFamily || make || metaTitle,
    description: metaDescription,
    review: {
      '@context': 'https://schema.org',
      name: altTitle || metaTitle,
      author: authors,
      image,
      dateModified,
      datePublished,
      publisher: {
        '@type': 'Organization',
        name: siteName,
        logo: {
          '@type': 'ImageObject',
          url: `${siteUrl}${logoImageSrc}`,
          height: logoImageHeight,
          width: logoImageWidth,
        },
      },
      ...getRating(rating),
      reviewBody: verdict,
    },
  };
  if (make) {
    jsonld.brand = {
      '@type': 'Brand',
      name: make,
    };
  }

  // TODO: check helemt data for owner review
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(jsonld)}</script>
      <title>{metaTitle}</title>
      <meta property="og:type" content="article" />
    </Helmet>
  );
};

getHelmetForOwnerReview.propTypes = {
  layoutData: PropTypes.shape({
    page: PropTypes.shape({
      altTitle: PropTypes.string,
      authors: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        bio: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        photo: PropTypes.string,
      })),
      teaserImage: PropTypes.shape({
        id: PropTypes.string,
        src: PropTypes.string,
        height: PropTypes.number,
        width: PropTypes.number,
        alt: PropTypes.string,
        source: PropTypes.string,
        credit: PropTypes.string,
        caption: PropTypes.string,
      }),
      date: PropTypes.string,
      created: PropTypes.string,
    }),
  }).isRequired,
  metaData: PropTypes.shape({
    pageTitle: PropTypes.string,
    pageDescription: PropTypes.string,
  }).isRequired,
  dataLayer: PropTypes.shape({
    product: PropTypes.string,
    productFamily: PropTypes.string,
    make: PropTypes.string,
  }).isRequired,
  config: PropTypes.shape({
    siteUrl: PropTypes.string,
    branding: PropTypes.shape({
      logoPng: PropTypes.shape({
        src: PropTypes.string,
        height: PropTypes.number,
        width: PropTypes.number,
      }),
    }),
    globalSettings: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};

export default getHelmetForOwnerReview;
