import React from 'react';
import PropTypes from 'prop-types';
import { OwnerSummary } from 'Molecules/OwnerSummary';

export const getOwnerReviewSummary = ({ reviewSummary }) => {
  if (!reviewSummary) {
    return null;
  }

  const {
    recommendedPercentage,
    problems,
  } = reviewSummary;

  const getCasesText = (problemCount) => {
    if (problemCount === 1) {
      return 'case';
    }
    return 'cases';
  };

  const commonProblems = (
    <ul>
      {problems.map((problem, problemIndex) => (
        <li key={`commonProblem-${problemIndex}`}>
          <span>{problem.problem}</span>
          {` (${problem.count} ${getCasesText(problem.count)})`}
        </li>
      ))}
    </ul>
  );

  return (
    <OwnerSummary
      recommendationPercent={`${recommendedPercentage}%`}
      commonProblems={commonProblems}
    />
  );
};

getOwnerReviewSummary.propTypes = {
  reviewSummary: PropTypes.shape({
    recommendedPercentage: PropTypes.number,
    avgRatingBreakdown: PropTypes.arrayOf(
      PropTypes.shape({
        rating: PropTypes.number,
        count: PropTypes.number,
      }),
    ),
    problems: PropTypes.arrayOf(
      PropTypes.shape({
        problem: PropTypes.string,
        count: PropTypes.number,
      }),
    ),
  }),

};

getOwnerReviewSummary.defaultProps = {
  reviewSummary: null,
};
