/**
 * @render react
 * @name OwnerReviewForm
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withPolaris } from '@autovia-uk/polaris-components/components/protons/Polaris';
import './_style.scss';

import {
  OwnerReviewBody,
  OwnerReviewFooter,
  OwnerReviewHeader,
} from 'Molecules/OwnerReviewForm/OwnerReviewContent';
import CompletedFormModal from './CompletedFormModal';
import OwnerReviewModal from './OwnerReviewModal';


/**
 * OwnerReviewForm component
 *
 * @param {Object} props
 * @param {Object} props.articleId - Id of review article from which the form modal was triggered
 * @param {Object} props.modalOpen
 * @param {Object} props.setModalOpen
 * @param {Object} props.useVRMDataFetch - hook used to fetch data about the vrm inputted
 * @param {Object} props.datalakeProxyUrl - url to send data to
 * @param {Object} props.sendDataToDatalakeApi - method used to send data to datalake
 * @param {Object} props.context - Extra information coming from Polaris provider
 */

const OwnerReviewFormModal = (
  {
    articleId,
    modalOpen,
    setModalOpen,
    useVRMDataFetch,
    datalakeProxyUrl,
    sendDataToDatalakeApi,
    context,
  },
) => {
  const [completedFormModalOpen, setCompletedFormModalOpen] = useState(false);

  const toggleCompletedFormModal = () => {
    setCompletedFormModalOpen(!completedFormModalOpen);
  };

  const toggleModal = (submitted = false) => {
    setModalOpen(!modalOpen);

    if (submitted) {
      toggleCompletedFormModal();
    }
  };

  return (
    <>
      <OwnerReviewModal
        modalOpen={modalOpen}
        toggleModal={toggleModal}
        headerContent={(
          <OwnerReviewHeader context={context} />
        )}
        bodyContent={(
          <OwnerReviewBody
            articleId={articleId}
            useVRMDataFetch={useVRMDataFetch}
            datalakeProxyUrl={datalakeProxyUrl}
            sendDataToDatalakeApi={sendDataToDatalakeApi}
            submitAction={toggleModal}
            context={context}
          />
        )}
        footerContent={(
          <OwnerReviewFooter context={context} />
        )}
      />
      <CompletedFormModal completedFormModalOpen={completedFormModalOpen} toggleCompletedFormModal={toggleCompletedFormModal} />
    </>
  );
};

/**
 * Valid props
 */
OwnerReviewFormModal.propTypes = {
  articleId: PropTypes.string,
  modalOpen: PropTypes.bool,
  setModalOpen: PropTypes.func,
  useVRMDataFetch: PropTypes.object,
  datalakeProxyUrl: PropTypes.string,
  sendDataToDatalakeApi: PropTypes.func,
  context: PropTypes.shape({
    cssPrefix: PropTypes.string,
  }),
};

/**
 * Default props
 */
OwnerReviewFormModal.defaultProps = {
  articleId: '',
  modalOpen: false,
  setModalOpen: null,
  useVRMDataFetch: {
    state: {},
    data: {},
  },
  datalakeProxyUrl: '',
  sendDataToDatalakeApi: null,
  context: {},
};

export default withPolaris(OwnerReviewFormModal);
